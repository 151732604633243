import React from 'react';
import { Event, EventStatus } from '../../api/events';
import { statusToString } from '../../pages/sessions/founder-sessions-page/utils';
import { getRoutePath, Pages } from '../../router/constants';
import { isMobile } from '../../utils/functions';
import { Table } from '../common/Table/Table';
import EventTypeIcon from '../common/event-type-icon';

export interface FounderSessionsTableProps {
  events: Event[];
}

export function FounderSessionsTable({ events }: FounderSessionsTableProps) {
  return (
    <Table
      columns={
        isMobile()
          ? {
              preIcon: {
                type: 'preIcon',
                title: '',
              },
              name: {
                type: 'name',
                title: 'Session Name',
              },
              date: {
                type: 'timeRange',
                title: 'Date',
              },
            }
          : {
              preIcon: {
                type: 'preIcon',
                title: '',
              },
              name: {
                type: 'name',
                title: 'Session Name',
              },
              status: {
                type: 'badge',
                title: 'Status',
              },
              date: {
                type: 'timeRange',
                title: 'Date',
              },
              venture: {
                type: 'link',
                title: 'Venture',
              },
            }
      }
      data={events.map((event) => {
        return {
          columns: {
            preIcon: <EventTypeIcon event={event} />,
            name: {
              label: event.summary,
              link: getRoutePath(Pages.SESSIONS_DETAILS, {
                sessionId: event.id,
              }),
            },
            venture: {
              label: event.ventureName,
              link: getRoutePath(Pages.VENTURE_DETAILS, {
                ventureId: event.ventureId,
              }),
            },
            date: [new Date(event.start), new Date(event.end)] as [Date, Date],
            status: {
              label: statusToString(event.status),
              variant: getVariantStatus(event.status),
            },
          },
        };
      })}
    />
  );
}

function getVariantStatus(
  status: EventStatus,
): 'default' | 'success' | 'warning' | 'error' | 'primary' {
  switch (status) {
    case 'CREATED':
      return 'warning';
    case 'PUBLISHED':
      return 'success';
    case 'COMPLETED':
      return 'primary';
    case 'ARCHIVED':
      return 'default';
    case 'REQUIRE_ATTENTION':
      return 'error';
  }
}
