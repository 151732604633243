import { Grid } from '@material-ui/core';
import { ReactElement } from 'react';
import { Message } from '../../../components/FrontPages/Message';
import Button from '../../../components/common/button';
import * as Actions from './types/Actions';
import { useCancelPage } from './useCancelPage';

export function CancelPage(): ReactElement {
  const [state, dispatch] = useCancelPage();

  switch (state.type) {
    case 'InvalidKey':
      return (
        <Message
          type={'error'}
          message={
            <>
              Invalid request, the authorization key seems to be invalid. Please
              share the URL with the support team at{' '}
              <a href={'mailto:support@tractionfive.com'}>
                support@tractionfive.com
              </a>
              .
            </>
          }
        />
      );
    case 'CancellingError':
      return (
        <Message
          type={'error'}
          message={
            <>
              Unfortunately, an error occurred while submitting your request.
              Please try again in a few minutes. If the problem persists, please
              share the URL with the support team at support@tractionfive.com.
              <br />
              Details: {state.payload.message}
            </>
          }
        />
      );
    case 'CancellingSuccess':
      return (
        <Message
          type={'success'}
          message={'You successfully deleted an existing Scheduling Wizard'}
        />
      );
    case 'Declined':
      return (
        <Message
          type={'simple'}
          message={'You did not delete an existing Scheduling Wizard'}
        />
      );
    case 'Ready':
    case 'Cancelling':
      return (
        <Message
          type={'simple'}
          message={
            <Grid container justify={'center'} spacing={2}>
              <Grid item xs={12}>
                You are about to cancel an existing Scheduling Wizard. Are you
                sure?
              </Grid>
              <Grid item container xs={10} spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant={'contained'}
                    onClick={() => dispatch(Actions.submit(true))}
                    disabled={state.type === 'Cancelling'}
                    data-testid={'confirm'}
                    fullWidth>
                    Yes
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant={'outlined'}
                    onClick={() => dispatch(Actions.submit(false))}
                    disabled={state.type === 'Cancelling'}
                    data-testid={'decline'}
                    fullWidth>
                    No
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          }
        />
      );
  }
}
