import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { parse as parseQuery } from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import _pick from 'lodash/pick';
import schedulingWizardApi, {
  SchedulingWizard,
  SchedulingWizardNewSlots,
} from '../../api/schedulingWizard';
import { getSettings } from '../../api/tenants';
import { TenantTimeZone } from '../../api/tenants/types/Settings';
import { SnackMessage, Text } from '../../components/common';
import SchedulingWizardInitialForm, {
  FormInitialValues,
  ParsedFormValues as FormValues,
} from '../../components/forms/scheduling-wizard-initial-form';
import { COLORS } from '../../theme/variables';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: '100%',
    padding: 0,
    height: '100%',

    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 60px)',
    },

    [theme.breakpoints.up('md')]: {
      width: '100%',
    },
  },
  block: {
    width: '700px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      borderRadius: '16px',
    },
  },
  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    height: '100vh',
    padding: 0,
    alignItems: 'center',

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  successBlock: {
    width: 300,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 15,
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
  successText: {
    textAlign: 'center',
  },
  title: {
    marginBottom: 15,
  },
}));

function parseFilterFromURL(search: string): any {
  return _pick(parseQuery(search), ['key']);
}

const formatValuesRequest = (
  values: FormValues,
  fullDetails: SchedulingWizard,
  key: string,
): SchedulingWizardNewSlots => {
  const schedulingWizardDates = values.arrayDate.map((date) => ({
    schedulingWizardId: fullDetails.id,
    startDateTime: date,
    endDateTime: date,
  }));

  return {
    id: fullDetails.id,
    tenantId: fullDetails.tenantId,
    ventureId: fullDetails.ventureId,
    key,
    originatorEmail: values.email,
    schedulingWizardDates,
    physicalLocation:
      values.meeting === 'in-person' || values.meeting === 'hybrid'
        ? values.physicalLocation || null
        : null,
    virtualChannelNeeded:
      values.meeting === 'virtually' || values.meeting === 'hybrid',
  };
};

const getInitialValues = (fullDetails: Payload): FormInitialValues => {
  return {
    venture: fullDetails.ventureName || '',
    meeting: 'virtually',
    physicalLocation:
      fullDetails.physicalLocation ??
      fullDetails.defaultPhysicalLocation ??
      undefined,
  };
};

interface Payload extends SchedulingWizard {
  defaultPhysicalLocation: string | undefined;
  timeZone: TenantTimeZone;
}

function ReportPage() {
  const classes = useStyles();
  const location = useLocation();
  const [fullDetails, setFullDetails] = useState<Payload>();
  const [statusForm, setStatusForm] = useState<
    'success' | 'loading' | 'error' | ''
  >('loading');
  const [isLoadingSaveForm, setIsLoadingSaveForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');

  const { key } = useMemo(
    () => parseFilterFromURL(location.search),
    [location.search],
  );

  const initialValues = useMemo(() => {
    if (fullDetails) {
      return getInitialValues(fullDetails);
    }
    return undefined;
  }, [fullDetails]);

  const handleSubmit = async (values: FormValues) => {
    const valuesRequest = formatValuesRequest(
      values,
      fullDetails as SchedulingWizard,
      key,
    );
    try {
      setIsLoadingSaveForm(true);
      await schedulingWizardApi.schedulingWizardSlots(valuesRequest);
      setStatusForm('success');
      setIsLoadingSaveForm(false);
    } catch (e: any) {
      setIsLoadingSaveForm(false);
      const messageError = e.response?.data?.message || 'Internal server error';
      enqueueSnackbar('An error occurred while saving your report', {
        variant: 'error',
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const loadDetails = async (key: string) => {
    try {
      setStatusForm('loading');
      const responseDetails = await schedulingWizardApi.getSchedulingWizard(
        key,
      );
      const { defaultPhysicalLocation, timeZone } = await getSettings(
        responseDetails.tenantId,
      );
      setFullDetails({
        ...responseDetails,
        defaultPhysicalLocation: defaultPhysicalLocation ?? undefined,
        timeZone,
      });
      setStatusForm('');
    } catch (e: any) {
      setError(e.response?.data?.message || 'Internal server error');
      setStatusForm('error');
    }
  };

  useEffect(() => {
    loadDetails(key);
  }, [key]);

  useEffect(() => {
    document.body.style.minWidth = 'auto';
  }, []);

  if (statusForm === 'loading') {
    return (
      <div
        data-testid='report-page'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.loadingBlock)}>
          <CircularProgress size={54} color='primary' />
        </div>
      </div>
    );
  }

  if (statusForm === 'success') {
    return (
      <div
        data-testid='report-page-success'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CheckCircleIcon className={classes.successIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>
              Thank you! Your form has been submitted successfully!
            </Text>
          </div>
        </div>
      </div>
    );
  }

  if (statusForm === 'error' || !fullDetails) {
    return (
      <div
        data-testid='report-page-error'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>
              Unfortunately, an error occurred while submitting your form.
              Please try again in a few minutes. If the problem persists, please
              share the URL with the support team at support@tractionfive.com.
              <br />
              Details: {error}
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div data-testid='report-page' className={classes.container}>
      <div className={classes.block}>
        <Typography variant='h2' className={classes.title}>
          Scheduling Wizard. Provide Time Slots
        </Typography>
        <SchedulingWizardInitialForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          loading={isLoadingSaveForm}
          mentors={fullDetails.mentorList}
          timeZone={fullDetails.timeZone}
        />
      </div>
    </div>
  );
}

export default ReportPage;
