import { Chip, CircularProgress, InputAdornment } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { Role } from '../../api/user/Role';
import venturesAPI from '../../api/ventures';
import { Venture } from '../../api/ventures/types/Venture';
import { UserContext } from '../../contexts/user-context';
import VentureAutocomplete from '../autocomplete/venture-autocomplete';
import {
  AssignmentRecommendedTable,
  Button,
  FormButtons,
  FormGroup,
  TextField,
} from '../common';

interface AssignFoundersVentureFormProps {
  isLoading: boolean;
  onSubmit: ({ ventures }: { ventures: Venture[] }) => Promise<void>;
  onCancel: () => void;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%',
    minHeight: 285,
    display: 'flex',
    flexDirection: 'column',

    '& .MuiChip-root': {
      overflow: 'hidden',
    },
  },
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
  checkboxGroup: {
    maxHeight: 360,
    overflow: 'auto',
  },
  loader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  chips: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row wrap',
    margin: '15px 0 0 -10px',
  },
  chipItem: {
    margin: '0 0 10px 10px',
    overflow: 'hidden',

    [theme.breakpoints.up('sm')]: {
      overflow: 'initial',
    },
  },
}));

function AssignSchedulingWizardForm({
  isLoading,
  onCancel,
  onSubmit,
}: AssignFoundersVentureFormProps) {
  const classes = useStyles();
  const { hasRole } = useContext(UserContext);
  const [initialVentures, setInitialVentures] = useState<Venture[]>([]);
  const [recommended, setRecommended] = useState<Venture[]>();
  const [displayedVentures, setDisplayedVentures] = useState<Venture[]>([]);
  const [isLoadingRecommended, setIsLoadingRecommended] = useState(
    !recommended,
  );
  const labelsVenture = useMemo(() => {
    return displayedVentures.length === 0
      ? 'Ventures With No Sessions and No Wizards'
      : 'Search Results';
  }, [displayedVentures]);

  const handleVenturesSearch = useCallback((searchedVentures: Venture[]) => {
    setDisplayedVentures(searchedVentures);
  }, []);

  const handleVentureRemove = (venture: Venture) => {
    setInitialVentures((prevVentures) =>
      prevVentures.filter((prevVenture) => prevVenture.id !== venture.id),
    );
  };

  const handleVentureAdd = (venture: Venture) => {
    setInitialVentures((prevVentures) => [...prevVentures, venture]);
  };

  useEffect(() => {
    const loadRecommended = async () => {
      try {
        const result = hasRole(Role.Mentor)
          ? await venturesAPI.getVenturesMentorRelated()
          : await venturesAPI.getVenturesWithNoWizards();
        const MAX_RECOMMENDED = 20;
        const resultLimited = result.slice(0, MAX_RECOMMENDED);
        setRecommended(resultLimited);
        setIsLoadingRecommended(false);
      } catch (e: any) {
        console.error('error loadRecommended');
        setIsLoadingRecommended(false);
      }
    };

    loadRecommended();
  }, [hasRole]);

  if (isLoadingRecommended) {
    return (
      <div className={classes.form}>
        <div className={classes.loader}>
          <CircularProgress size={36} color='primary' />
        </div>
        <FormButtons>
          <Button onClick={onCancel} variant='outlined'>
            Cancel
          </Button>
          <Button disabled>Apply</Button>
        </FormButtons>
      </div>
    );
  }

  const initialValues = {
    venturesIds: initialVentures.map((venture) => venture.id),
  };

  const filteredDisplayedVentures = displayedVentures.filter(
    (venture) =>
      !initialVentures.find(
        (initialVenture) => initialVenture.id === venture.id,
      ),
  );
  const filteredRecommendedVentures = (recommended || []).filter(
    (item) => !initialVentures.find((venture) => venture.id === item.id),
  );

  const displayedList =
    filteredDisplayedVentures.length > 0
      ? filteredDisplayedVentures
      : filteredRecommendedVentures;

  return (
    <Form
      initialValues={initialValues}
      onSubmit={() => onSubmit({ ventures: initialVentures })}
      keepDirtyOnReinitialize
      render={(formProps) => (
        <form
          className={classes.form}
          onSubmit={formProps.handleSubmit}
          noValidate>
          <FormGroup>
            <VentureAutocomplete
              onSearch={handleVenturesSearch}
              isMentorRelated={hasRole(Role.Mentor)}
              inputRenderer={({ value, onChange, isLoading }) => (
                <TextField
                  value={value}
                  label='Schedule New Session(s) for'
                  placeholder='Enter venture name'
                  onChange={onChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: !!isLoading && (
                      <CircularProgress color='primary' size={20} />
                    ),
                  }}
                />
              )}
            />
          </FormGroup>
          <div className={classes.chips}>
            {initialVentures.map((venture) => (
              <Chip
                key={venture.id}
                label={venture.ventureName}
                onDelete={() => handleVentureRemove(venture)}
                className={classes.chipItem}
              />
            ))}
          </div>
          {displayedList.length > 0 && (
            <AssignmentRecommendedTable
              labels={[labelsVenture, '']}
              rows={displayedList.map((recomItem) => ({
                name: recomItem.ventureName,
                onAdd: () => handleVentureAdd(recomItem),
              }))}
            />
          )}
          <FormButtons className={classes.formButtons}>
            <Button onClick={onCancel} variant='outlined'>
              Cancel
            </Button>
            <Button
              disabled={isLoading || initialVentures.length === 0}
              type='submit'
              data-testid='button-apply'>
              {isLoading ? (
                <CircularProgress size={24} color='inherit' />
              ) : (
                'Apply'
              )}
            </Button>
          </FormButtons>
        </form>
      )}
    />
  );
}

export default AssignSchedulingWizardForm;
