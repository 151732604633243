import { Tooltip } from '@material-ui/core';
import ComputerIcon from '@material-ui/icons/Computer';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import PeopleIcon from '@material-ui/icons/People';
import { FC, useMemo } from 'react';
import { Event } from '../../api/events';
import { COLORS } from '../../theme/variables';

interface EventTypeIconProps {
  event: Event;
}

const getType = (event: Event) => {
  if (event.physicalLocation && event.tenantEventChannelId) {
    return 'Hybrid';
  } else if (event.physicalLocation) {
    return 'In-person';
  } else if (event.tenantEventChannelId) {
    return 'Virtual';
  } else {
    return '';
  }
};

const EventTypeIcon: FC<EventTypeIconProps> = ({ event }) => {
  const type = useMemo(() => getType(event), [event]);

  switch (type) {
    case 'Hybrid':
      return (
        <Tooltip title={'Hybrid'}>
          <GroupWorkIcon
            style={{ color: COLORS.COLOR_ADDITIONAL_VIOLET }}
            fontSize='small'
          />
        </Tooltip>
      );
    case 'In-person':
      return (
        <Tooltip title={'In-person'}>
          <PeopleIcon
            style={{ color: COLORS.COLOR_GREEN_LIGHTENED_10 }}
            fontSize='small'
          />
        </Tooltip>
      );
    case 'Virtual':
      return (
        <Tooltip title={'Virtual'}>
          <ComputerIcon
            style={{ color: COLORS.COLOR_ADDITIONAL_SEA_SERPENT }}
            fontSize='small'
          />
        </Tooltip>
      );
    default:
      return null;
  }
};

export default EventTypeIcon;
