import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useState } from 'react';
import schedulingWizardApi from '../../api/schedulingWizard';
import { Venture } from '../../api/ventures/types/Venture';
import { COLORS } from '../../theme/variables';
import AssignSchedulingWizardForm from '../forms/assign-scheduling-wizard';
import { Button, Dialog, SnackMessage, Text } from './index';

interface AssignSchedulingModalProps {}

enum AssignSchedulingModalSteps {
  IDLE = 'IDLE',
  ASSIGN_VENTURE = 'ASSIGN_VENTURE',
  RESERVED = 'RESERVED',
  ERROR = 'ERROR',
}

const useStyles = makeStyles(() => ({
  buttonInfoIcon: {
    padding: 0,
    marginLeft: 7,
  },
  infoIcon: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
    fontSize: 18,
  },
  ventureList: {
    listStyleType: 'disc',
    margin: '10px 0 10px 20px',
    wordBreak: 'break-word',
    fontWeight: 500,
  },
  errorText: {
    '& b, li': {
      wordBreak: 'break-word',
      fontWeight: 500,
    },
  },
}));

const Title: FC<{ tooltip?: string }> = ({ tooltip }) => {
  const classes = useStyles();

  return (
    <>
      Scheduling Wizard
      {tooltip && (
        <Tooltip title={tooltip}>
          <IconButton className={classes.buttonInfoIcon}>
            <InfoIcon className={classes.infoIcon} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export const AssignSchedulingWizardModal: FC<AssignSchedulingModalProps> = (
  props,
) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [step, setStep] = useState(AssignSchedulingModalSteps.IDLE);
  const [isLoading, setIsLoading] = useState(false);
  const [reservedVentures, setReservedVentures] = useState<Venture[]>([]);
  const [failedVentures, setFailedVentures] = useState<Venture[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const onCancel = useCallback(() => {
    setStep(AssignSchedulingModalSteps.IDLE);
    setReservedVentures([]);
    setFailedVentures([]);
    setErrorMessage(null);
  }, []);

  const handleSubmit = useCallback(
    async ({ ventures }: { ventures: Venture[] }) => {
      try {
        setIsLoading(true);
        setReservedVentures([]);
        setFailedVentures([]);
        setErrorMessage(null);
        const newReservedVentures: Venture[] = [];
        const newFailedVentures: Venture[] = [];
        let newErrorMessage = null;

        for (const venture of ventures) {
          try {
            await schedulingWizardApi.create(venture.id);
          } catch (error) {
            const message = (error as any)?.response?.data?.message as string;
            const isReserved =
              message ===
              "Cannot create a new Scheduling Wizard - there's one active exists for the Venture";

            if (isReserved) {
              newReservedVentures.push(venture);
            } else {
              newErrorMessage = message;
              newFailedVentures.push(venture);
            }
          }
        }

        setErrorMessage(newErrorMessage);
        setReservedVentures(newReservedVentures);
        setFailedVentures(newFailedVentures);

        if (newReservedVentures.length) {
          setStep(AssignSchedulingModalSteps.RESERVED);
        } else if (newFailedVentures.length) {
          setStep(AssignSchedulingModalSteps.ERROR);
        } else {
          enqueueSnackbar(
            'Scheduling process started successfully!\nNew session(s) will be scheduled in 4 days.',
            {
              variant: 'success',
            },
          );
          onCancel();
        }

        setIsLoading(false);
      } catch (e: any) {
        const messageError = e.response?.data?.message;

        enqueueSnackbar(
          'An error occurred while creating the scheduling wizard. Please, try again.',
          {
            content: (key, message) =>
              SnackMessage({
                key,
                message,
                variant: 'error',
                additionalMessage: messageError,
              }),
            variant: 'error',
          },
        );

        setIsLoading(false);
      }
    },
    [enqueueSnackbar, onCancel],
  );

  const handleRestart = useCallback(async () => {
    try {
      setIsLoading(true);
      const newFailedVentures: Venture[] = [];
      let newErrorMessage = null;

      for (const venture of reservedVentures) {
        try {
          const wizards = await schedulingWizardApi.ventureActiveWizards(
            venture.id,
          );

          for (const wizard of wizards) {
            await schedulingWizardApi.cancel(wizard.id);
          }

          await schedulingWizardApi.create(venture.id);
        } catch (error) {
          const message = (error as any)?.response?.data?.message as string;
          newErrorMessage = message;
          newFailedVentures.push(venture);
        }
      }

      setErrorMessage(newErrorMessage);
      setFailedVentures((prev) => [...prev, ...newFailedVentures]);

      if (newFailedVentures.length) {
        setStep(AssignSchedulingModalSteps.ERROR);
      } else {
        enqueueSnackbar(
          'Scheduling process started successfully!\nNew session(s) will be scheduled in 4 days.',
          {
            variant: 'success',
          },
        );
        onCancel();
      }

      setIsLoading(false);
    } catch (e: any) {
      const messageError = e.response?.data?.message;

      enqueueSnackbar(
        'An error occurred while creating the scheduling wizard. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: messageError,
            }),
          variant: 'error',
        },
      );

      setIsLoading(false);
    }
  }, [enqueueSnackbar, onCancel, reservedVentures]);

  return (
    <>
      <Button
        onClick={() => setStep(AssignSchedulingModalSteps.ASSIGN_VENTURE)}
        data-testid='button-scheduling-wizard'
        startIcon={<AddIcon />}>
        Wizard
      </Button>
      {step === AssignSchedulingModalSteps.ASSIGN_VENTURE && (
        <Dialog
          title={
            <Title tooltip='This action will initiate the scheduling of Wizards for one or more ventures. Founders will provide meeting date options. To set dates manually, you can launch a new Wizard for each venture directly from the Venture page' />
          }
          contentRenderer={() => (
            <AssignSchedulingWizardForm
              onCancel={onCancel}
              onSubmit={handleSubmit}
              isLoading={isLoading}
            />
          )}
          open={true}
          setOpen={onCancel}
          width={720}
        />
      )}
      {step === AssignSchedulingModalSteps.RESERVED &&
        reservedVentures.length > 0 && (
          <Dialog
            title='Scheduling Wizard'
            contentRenderer={() => (
              <Text variant='normal'>
                We found several ventures where Scheduling Wizards are already
                running.
                <ul className={classes.ventureList}>
                  {reservedVentures.map((venture) => (
                    <li key={venture.id}>{venture.ventureName}</li>
                  ))}
                </ul>
                You can either restart Scheduling Wizards for all of them or
                keep them running as is. Let us know your preference.
              </Text>
            )}
            open={true}
            setOpen={onCancel}
            width={400}
            actions={[
              <Button
                key='wizard-keep-running'
                onClick={onCancel}
                variant='outlined'
                data-testid='wizard-keep-running'>
                Keep
              </Button>,
              <Button
                key='wizard-restart'
                onClick={handleRestart}
                variant='contained'
                disabled={isLoading}
                data-testid='wizard-restart'>
                {isLoading ? (
                  <CircularProgress size={24} color='inherit' />
                ) : (
                  'Restart'
                )}
              </Button>,
            ]}
          />
        )}
      {step === AssignSchedulingModalSteps.ERROR && failedVentures.length > 0 && (
        <Dialog
          title='Scheduling Wizard'
          contentRenderer={() =>
            failedVentures.length === 1 ? (
              <Text variant='normal' className={classes.errorText}>
                An error occurred while starting a new wizard for the venture{' '}
                <b>{failedVentures[0].ventureName}</b>.
                <br />
                <br />
                {errorMessage}
              </Text>
            ) : (
              <Text variant='normal'>
                Unfortunately we ran into a problem while starting new wizards
                for selected companies, please try to start wizards manually
                from the Venture page.
                <ul className={cn(classes.ventureList, classes.errorText)}>
                  {failedVentures.map((venture) => (
                    <li key={venture.id}>{venture.ventureName}</li>
                  ))}
                </ul>
              </Text>
            )
          }
          open={true}
          setOpen={onCancel}
          width={400}
          actions={[
            <Button
              onClick={onCancel}
              variant='outlined'
              data-testid='wizard-ok'>
              Ok
            </Button>,
          ]}
        />
      )}
    </>
  );
};
