import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { isT } from 'fp-utilities';
import { useSnackbar } from 'notistack';
import { useCallback, useMemo, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { EventFullDetails } from '../../api/events';
import { Founder } from '../../api/founders';
import { Mentor } from '../../api/mentors';
import { TenantTimeZone } from '../../api/tenants/types/Settings';
import FieldDates from '../../components/common/field-dates-report-lead-mentor';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { useScrollOnValidation } from '../../hooks/useScrollOnValidation';
import { formatDateToRFC, isValidateDate } from '../../utils/date';
import { isStringDate, toDate } from '../../utils/date/StringDate';
import { ZonedDate } from '../../utils/date/ZonedDate';
import { lengthField, SELECT_NONE } from '../../utils/form';
import { getTextWithoutTags, validateEmail } from '../../utils/functions';
import { length } from '../../utils/wysiwyg';
import {
  AttachFilesButton,
  Button,
  CheckboxList,
  FormButtons,
  FormGroup,
} from '../common';
import { DatesOutOfRange } from '../common/Confirmation/DatesOutOfRange';
import AttachmentCard, {
  Attachment,
  getAttachmentFromFile,
} from '../common/attachment-card';
import Text from '../common/text';
import {
  FormDateTimeInput,
  FormRadioboxGroup,
  FormRating,
  FormSelect,
  TextFieldSummary,
  TextFieldWrapper,
  TextFieldWysiwyg,
} from './wrappers';

const useStyles = makeStyles((theme) => ({
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  formContainer: {
    width: '100%',
  },
  title: {
    marginTop: 20,
  },

  ventureUpdate: {
    '& .MuiInputBase-inputMultiline': {
      minHeight: 64,
    },
  },
  attachBtn: {
    marginTop: 24,
  },
  attachmentsList: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '20px 0 -16px -16px',
  },
  attachment: {
    width: '100%',
    boxSizing: 'border-box',
    padding: '0 0 16px 16px',

    [theme.breakpoints.up('xs')]: {
      width: '50%',
    },
  },
  progressVenture: {
    marginBottom: '10px !important',
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
  fileName: {
    maxWidth: '200px !important',
  },
}));

export interface Params {
  label: string;
  value: string;
}

type MeetingType = 'virtually' | 'in-person' | 'hybrid';

export interface FormValues {
  submitterEmail: string;
  summary: string;
  start: string;
  end: string;
  sessionRating: number;
  ventureProgressRating: number;
  attendingMentors: string[];
  attendingFounders: string[];
  mentorReport: string;
  mentorReportNotes: string;
  nextEventStart: string;
  nextEventEnd: string;
  rateSession: number;
  rateVenture: number;
  attachments: string;
  mostImpactfulMentor: string;
  physicalLocation: string | undefined;
  meeting: MeetingType;
}

export interface FormInitialValues {
  submitterEmail: string;
  summary: string;
  start: string;
  end: string;
  sessionRating: number;
  ventureProgressRating: number;
  attendingMentors: string[];
  attendingFounders: string[];
  mentorReport: string;
  mentorReportNotes: string;
  nextEventStart: string;
  nextEventEnd: string;
  rateSession: number;
  rateVenture: number;
  attachments: Attachment[];
  mostImpactfulMentor: string;
  meeting: MeetingType;
  physicalLocation: string | undefined;
}

interface Props {
  fullDetails: EventFullDetails;
  onSubmit: (values: FormValues) => void;
  onUploadFile: (file: File) => Promise<string | undefined>;
  loading: boolean;
  initialValues?: FormInitialValues;
  errorVaildDate?: boolean;
  optionsMentor: Params[];
  leadMentorEnabled: boolean;
  timeZone: TenantTimeZone;
  reportBasedSchedulingEnabled: boolean;
}

type Errors = {
  [K in keyof FormValues]?: string;
};

const validateForm = (values: FormValues) => {
  const errors: Errors = {};

  if (values.nextEventStart && !values.nextEventEnd) {
    errors.nextEventEnd = 'Required';
  }

  if (!values.nextEventStart && values.nextEventEnd) {
    errors.nextEventStart = 'Required';
  }

  if (values.nextEventStart && !isValidateDate(values.nextEventStart)) {
    errors.nextEventStart = 'Invalid';
  }
  if (values.nextEventEnd && !isValidateDate(values.nextEventEnd)) {
    errors.nextEventEnd = 'Invalid';
  }

  if (!values.submitterEmail) {
    errors.submitterEmail = 'Required';
  } else if (!validateEmail(values.submitterEmail)) {
    errors.submitterEmail = 'Invalid';
  }
  if (!values.sessionRating) {
    errors.sessionRating = 'Required';
  }
  if (!values.ventureProgressRating) {
    errors.ventureProgressRating = 'Required';
  }
  if (!values.mentorReport || length(values.mentorReport) > 500) {
    errors.mentorReport = 'Required';
  }
  if (values.mentorReportNotes && length(values.mentorReportNotes) > 2000) {
    errors.mentorReportNotes = 'Invalid';
  }

  if (values.meeting === 'in-person' || values.meeting === 'hybrid') {
    if (!values.physicalLocation) {
      errors['physicalLocation'] = 'Required';
    } else if (values.physicalLocation.length > 2048) {
      errors['physicalLocation'] =
        'Address should be less then 2048 characters';
    }
  }

  return errors;
};

const formatAttachments = (attachments: Attachment[]) => {
  try {
    if (!attachments.length) {
      return '';
    }
    const attachmentRefs = JSON.stringify(attachments);
    return attachmentRefs;
  } catch (e: any) {
    return '';
  }
};

const getInitialAttendingMentors = (mentors: (Params | undefined)[]) => {
  return mentors && mentors.length > 0
    ? [SELECT_NONE, ...mentors]
    : [SELECT_NONE];
};

function ReportLeadMentorForm({
  fullDetails,
  onSubmit,
  loading,
  initialValues,
  errorVaildDate,
  onUploadFile,
  optionsMentor,
  leadMentorEnabled,
  timeZone,
  reportBasedSchedulingEnabled,
}: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const setSubmitValidationFailed = useScrollOnValidation();

  const isSubmitDisabled = loading;
  const [fieldsValidation, setFieldsValidation] = useState<{ date: boolean }>();
  const [currentAttendingMentors, setCurrentAttendingMentors] = useState<
    string[]
  >(initialValues?.attendingMentors || []);

  const [attachments, setAttachments] = useState<Attachment[]>(
    initialValues?.attachments || [],
  );

  const curretnOptionsMentor = useMemo(() => {
    const filterOptionsMentor =
      currentAttendingMentors.length > 0
        ? currentAttendingMentors.map((idMentor) => {
            return optionsMentor.find((mentor) => mentor.value === idMentor);
          })
        : [];
    return getInitialAttendingMentors(filterOptionsMentor);
  }, [currentAttendingMentors, optionsMentor]);

  const handleSubmit = (values: FormValues) => {
    const cleanText = getTextWithoutTags(values.mentorReport);
    if (cleanText.length > 1000) {
      enqueueSnackbar(
        'Progress report should not exceed 1000 characters.\nIf you would like to submit your longer notes,\nplease attach them as a file or add them manually in Traction5.',
        {
          variant: 'error',
          style: { whiteSpace: 'pre-line' },
        },
      );
      return;
    }

    const attachmentRefs = formatAttachments(attachments);
    onSubmit({ ...values, attachments: attachmentRefs });
  };

  const handleSelectFile = (
    e: React.ChangeEvent<HTMLInputElement>,
    file: File,
  ) => {
    handleFileUpload(file);
    e.target.value = '';
  };

  const handleFieldsValidation = useCallback(
    (field: 'date') => (valid: boolean) => {
      setFieldsValidation(
        (prevValidations) =>
          ({
            ...(prevValidations ? prevValidations : {}),
            [field]: valid,
          } as { date: boolean }),
      );
    },
    [],
  );

  const handleFileUpload = async (file: File) => {
    try {
      const fileIndex = attachments.length;
      setAttachments((prevAttachments) => [
        ...prevAttachments,
        getAttachmentFromFile(file),
      ]);
      const fileURL = await onUploadFile(file);
      setAttachments((prevAttachments) => {
        return prevAttachments.map((prevAttach, prevAttachIndex) => {
          if (prevAttachIndex !== fileIndex) {
            return prevAttach;
          }
          return {
            ...prevAttach,
            url: fileURL,
          };
        });
      });
    } catch (e: any) {}
  };

  const handleFileRemove = (index: number) => {
    setAttachments((prevAttachments) =>
      prevAttachments.filter((_, attachIndex) => attachIndex !== index),
    );
  };

  return (
    <DatesOutOfRange
      getDates={(v) =>
        [
          isStringDate(v.nextEventStart)
            ? new ZonedDate(toDate(v.nextEventStart), timeZone)
            : undefined,
          isStringDate(v.nextEventEnd)
            ? new ZonedDate(toDate(v.nextEventEnd), timeZone)
            : undefined,
        ].filter(isT)
      }
      onSubmit={handleSubmit}>
      {({ onSubmit }) => {
        return (
          <Form
            validate={validateForm}
            onSubmit={onSubmit}
            initialValues={initialValues}
            keepDirtyOnReinitialize
            render={(formProps) => {
              setSubmitValidationFailed(
                formProps.submitFailed &&
                  !formProps.dirtySinceLastSubmit &&
                  !formProps.submitting,
              );
              return (
                <div className={classes.formBlocks}>
                  <form noValidate className={classes.formContainer}>
                    <FormGroup>
                      <Field<string>
                        name='summary'
                        component={TextFieldSummary}
                        label='Summary'
                        disabled
                        testid='report-lead-mentor-form-summary'
                      />
                    </FormGroup>
                    <FormGroup mobile>
                      <Field<string>
                        name='start'
                        component={FormDateTimeInput}
                        label='Start'
                        parse={(value) => formatDateToRFC(value)}
                        disabled
                        testId='report-lead-mentor-form-start'
                      />
                      <Field<string>
                        name='end'
                        component={FormDateTimeInput}
                        label='End'
                        parse={(value) => formatDateToRFC(value)}
                        disabled
                        testId='report-lead-mentor-form-end'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='submitterEmail'
                        component={TextFieldWrapper}
                        label='Email*'
                        testid='report-lead-mentor-form-email'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.email,
                          },
                        }}
                        formatOnBlur
                        format={(value: string) => {
                          return value ? value.toLowerCase() : value;
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<number>
                        name='sessionRating'
                        component={FormRating}
                        label='How would you rate this session? *'
                        testid='report-lead-mentor-form-session-rating'
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<number>
                        name='ventureProgressRating'
                        component={FormRating}
                        label='How would you rate the progress of the venture? *'
                        className={classes.progressVenture}
                        testid='report-lead-mentor-form-venture-rating'
                      />
                    </FormGroup>

                    <div className={classes.title}>
                      <Typography variant='h4'>
                        Please select all {rb('mentors-u').toUpperCase()} who
                        were present
                      </Typography>
                    </div>
                    <FormGroup column>
                      {fullDetails?.mentorList &&
                      fullDetails?.mentorList.length > 0 ? (
                        fullDetails?.mentorList.map((mentor: Mentor) => {
                          const valueMentors =
                            formProps.values.attendingMentors;
                          return (
                            <CheckboxList
                              value={mentor.id}
                              label={`${mentor.firstName} ${mentor.lastName}`}
                              name='attendingMentors'
                              checked={valueMentors?.includes(mentor.id)}
                              onChange={(id: string) => {
                                formProps.form.change(
                                  'mostImpactfulMentor',
                                  '',
                                );

                                if (valueMentors.includes(id)) {
                                  const filterValue = valueMentors.filter(
                                    (mentorId) => mentorId !== id,
                                  );

                                  setCurrentAttendingMentors(filterValue);

                                  return formProps.form.change(
                                    'attendingMentors',
                                    filterValue,
                                  );
                                }

                                setCurrentAttendingMentors([
                                  ...valueMentors,
                                  id,
                                ]);

                                formProps.form.change('attendingMentors', [
                                  ...valueMentors,
                                  id,
                                ]);
                              }}
                            />
                          );
                        })
                      ) : (
                        <Text variant='normal'>
                          There are no invited {rb('mentors')}
                        </Text>
                      )}
                    </FormGroup>

                    <div className={classes.title}>
                      <Typography variant='h4'>
                        Please select all FOUNDERS who were present
                      </Typography>
                    </div>
                    <FormGroup column>
                      {fullDetails?.founderList &&
                      fullDetails?.founderList.length > 0 ? (
                        fullDetails?.founderList.map((founder: Founder) => {
                          const valueFounders =
                            formProps.values.attendingFounders;
                          return (
                            <CheckboxList
                              value={founder.id}
                              label={`${founder.firstName} ${founder.lastName}`}
                              name='attendingFounders'
                              checked={valueFounders?.includes(founder.id)}
                              onChange={(id: string) => {
                                if (valueFounders.includes(id)) {
                                  const filterValue = valueFounders.filter(
                                    (founderId) => founderId !== id,
                                  );

                                  return formProps.form.change(
                                    'attendingFounders',
                                    filterValue,
                                  );
                                }
                                formProps.form.change('attendingFounders', [
                                  ...valueFounders,
                                  id,
                                ]);
                              }}
                            />
                          );
                        })
                      ) : (
                        <Text variant='normal'>
                          There are no invited founders
                        </Text>
                      )}
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='mentorReport'
                        placeholder='Venture Progress Update. Progress update should not exceed 500 characters. Use the link below to attach files*'
                        component={TextFieldWysiwyg}
                        className={classes.ventureUpdate}
                        showCount={true}
                        maxLength={500}
                        testid='report-lead-mentor-form-mentor-report'
                        InputProps={{
                          inputProps: {
                            maxLength: lengthField.ventureUpdate,
                          },
                        }}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Field<string>
                        name='mentorReportNotes'
                        placeholder='Notes'
                        component={TextFieldWysiwyg}
                        className={classes.ventureUpdate}
                        showCount={true}
                        maxLength={2000}
                        testid='report-lead-mentor-form-mentor-report-notes'
                      />
                    </FormGroup>

                    {leadMentorEnabled && (
                      <FormGroup>
                        <Field<string>
                          name='mostImpactfulMentor'
                          component={FormSelect}
                          label={`Who was the most impactful ${rb(
                            'mentor',
                          )} during the session?`}
                          options={curretnOptionsMentor}
                        />
                      </FormGroup>
                    )}

                    {reportBasedSchedulingEnabled && (
                      <>
                        <FieldDates
                          onValid={handleFieldsValidation('date')}
                          errorVaildDate={errorVaildDate}
                        />
                        <br />
                        <Typography variant='h4'>
                          How would you like to meet?
                        </Typography>
                        <Field<string>
                          name='meeting'
                          component={FormRadioboxGroup}
                          options={[
                            { value: 'virtually', label: 'Virtually' },
                            {
                              value: 'in-person',
                              label: 'In-person',
                            },
                            {
                              value: 'hybrid',
                              label: 'Hybrid',
                              info: 'This hybrid meeting will have both in-person and virtual channels',
                            },
                          ]}
                          testid='scheduling-wizard-initial-form-meeting'
                        />
                      </>
                    )}
                    {formProps.values.meeting === 'in-person' ||
                    formProps.values.meeting === 'hybrid' ? (
                      <Field<string>
                        name='physicalLocation'
                        component={TextFieldWrapper}
                        placeholder={'Default location (editable)'}
                        testid='scheduling-wizard-initial-form-physicalLocation'
                      />
                    ) : null}

                    <div className={classes.attachBtn}>
                      <AttachFilesButton onChange={handleSelectFile} />
                    </div>
                    <div className={classes.attachmentsList}>
                      {attachments.map((attachment, attachIndex) => (
                        <div key={attachIndex} className={classes.attachment}>
                          <AttachmentCard
                            attachment={attachment}
                            loading={!attachment.url}
                            onRemove={() => handleFileRemove(attachIndex)}
                            classesFileName={classes.fileName}
                          />
                        </div>
                      ))}
                    </div>

                    <FormButtons className={classes.formButtons}>
                      <Button
                        data-testid='button-submit-form'
                        onClick={formProps.handleSubmit}
                        disabled={
                          isSubmitDisabled ||
                          (reportBasedSchedulingEnabled &&
                            !fieldsValidation?.date)
                        }>
                        {loading ? (
                          <CircularProgress size={24} color='inherit' />
                        ) : (
                          'Save'
                        )}
                      </Button>
                    </FormButtons>
                  </form>
                </div>
              );
            }}
          />
        );
      }}
    </DatesOutOfRange>
  );
}

export default ReportLeadMentorForm;
