import { IconButton, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { makeStyles } from '@material-ui/styles';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import NoteForm, { Values as FormValues } from '../../components/forms/note';
import { COLORS } from '../../theme/variables';
import { StringDate } from '../../utils/date/StringDate';
import { Attachment } from './attachment-card';

interface NewNoteProps {
  disabled?: boolean;
  creating?: boolean;
  onUploadFile?: (file: File) => Promise<string>;
  onCreate: (
    newNote: {
      contents: string;
      attachmentRefs: string;
      creationDate: StringDate;
    },
    callback: () => any,
  ) => any;
  onCreateModeChange?: (isEditing: boolean) => any;
}

const useStyles = makeStyles({
  container: {
    marginBottom: 56,
  },
  main: {
    display: 'flex',
    alignItems: 'center',
  },
  disabled: {
    opacity: 0.5,
    pointerEvents: 'none',
  },
  addBtn: {
    padding: 4,
    background: COLORS.COLOR_BLUE_BASE,
    color: 'white',
    marginLeft: 16,

    '&:hover': {
      color: 'white',
      background: COLORS.COLOR_BLUE_LIGHTENED_5,
      boxShadow:
        '0px 2px 4px rgba(34, 91, 187, 0.2), 0px 4px 8px rgba(51, 126, 255, 0.12)',
    },
  },
});

const formatAttachments = (attachments: Attachment[]) => {
  try {
    if (!attachments.length) {
      return '';
    }
    const attachmentRefs = JSON.stringify(attachments);
    return attachmentRefs;
  } catch (e: any) {
    return '';
  }
};

function NewNote({
  creating,
  disabled,
  onUploadFile,
  onCreate,
  onCreateModeChange,
}: NewNoteProps) {
  const classes = useStyles();
  const [isShownForm, setIsShownForm] = useState(false);

  const handleOpenForm = useCallback(() => {
    setIsShownForm(true);
    onCreateModeChange?.(true);
  }, [onCreateModeChange]);

  const handleCloseForm = useCallback(() => {
    setIsShownForm(false);
    onCreateModeChange?.(false);
  }, [onCreateModeChange]);

  const handleCreateNote = useCallback(
    (values: FormValues) => {
      const attachmentRefs = formatAttachments(values.attachments);
      onCreate(
        {
          contents: values.contents,
          attachmentRefs,
          creationDate: values.creationDate,
        },
        handleCloseForm,
      );
    },
    [handleCloseForm, onCreate],
  );

  const handleCreateCancel = useCallback(() => {
    handleCloseForm();
  }, [handleCloseForm]);

  return (
    <div className={classes.container}>
      {isShownForm ? (
        <NoteForm
          loading={creating}
          onSubmit={handleCreateNote}
          onUploadFile={onUploadFile}
          onCancel={handleCreateCancel}
        />
      ) : (
        <div
          className={classNames(classes.main, {
            [classes.disabled]: disabled,
          })}>
          <Typography variant='h4'>New Note</Typography>
          <IconButton
            className={classes.addBtn}
            onClick={handleOpenForm}
            data-testid='button-new-note'>
            <AddIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}

export default NewNote;
