export const INTER_FONT_FAMILY = 'Inter';
export const MONTSERRAT_FONT_FAMILY = 'Montserrat';

export enum COLORS {
  // Text
  COLOR_TEXT_BASE = '#091D3D',
  COLOR_TEXT_LIGHTENED_10 = 'rgba(9, 29, 61, 0.8)',
  COLOR_TEXT_LIGHTENED_20 = 'rgba(9, 29, 61, 0.6)',
  COLOR_TEXT_LIGHTENED_30 = 'rgba(9, 29, 61, 0.4)',
  // Gray
  COLOR_GRAY_DARKENED_15 = '#425166',
  COLOR_GRAY_DARKENED = '#57647B',
  COLOR_GRAY_BASE = '#95A3B9',
  COLOR_GRAY_LIGHTENED_20 = '#BEC6D3',
  COLOR_GRAY_LIGHTENED_30 = '#E0E4EB',
  COLOR_GRAY_LIGHTENED_40 = 'rgba(149, 163, 185, 0.15)',
  COLOR_GRAY_LIGHTENED_45 = 'rgba(149, 163, 185, 0.1)',
  COLOR_GRAY_LIGHTENED_50 = 'rgba(149, 163, 185, 0.05)',
  COLOR_GRAY_PLACEHOLDER = '#838890',

  // Blue
  COLOR_BLUE_DARKENED_30 = '#003187',
  COLOR_BLUE_DARKENED_10 = '#0F5EE6',
  COLOR_BLUE_BASE = '#2575FF',
  COLOR_BLUE_LIGHTENED_5 = '#3981FF',
  COLOR_BLUE_LIGHTENED_10 = '#4D8EFF',
  COLOR_BLUE_LIGHTENED_25 = 'rgba(37, 117, 255, 0.5)',
  COLOR_BLUE_LIGHTENED_35 = 'rgba(37, 117, 255, 0.25)',
  COLOR_BLUE_LIGHTENED_40 = 'rgba(37, 117, 255, 0.15)',
  COLOR_BLUE_LIGHTENED_45 = 'rgba(37, 117, 255, 0.1)',
  COLOR_BLUE_LIGHTENED_50 = 'rgba(37, 117, 255, 0.05)',
  // Red
  COLOR_RED_BASE = '#F43655',
  COLOR_RED_LIGHTENED = 'rgba(244, 54, 85, 0.1)',
  // Green
  COLOR_GREEN_BASE = '#16AD92',
  COLOR_GREEN_LIGHTENED_10 = '#24C2A6',
  COLOR_GREEN_LIGHTENED_45 = 'rgba(22, 173, 146, 0.1)',
  // Additional
  COLOR_ADDITIONAL_DARK_TRANS_60 = 'rgba(0, 11, 30, 0.6)',
  COLOR_ADDITIONAL_DARK_TRANS_40 = 'rgba(0, 11, 30, 0.4)',
  COLOR_ADDITIONAL_GRAY_BLUE_TEXT = '#6B9BE2',
  COLOR_ADDITIONAL_ORANGE = '#F78500',
  COLOR_ADDITIONAL_ORANGE_LIGHTENED = 'rgba(247, 133, 0, 0.1)',
  COLOR_ADDITIONAL_GENERAL_BACKGROUND = '#F7F9FC',
  COLOR_ADDITIONAL_PINK = '#FF758E',
  COLOR_ADDITIONAL_SEA_SERPENT = '#4EC9E4',
  COLOR_ADDITIONAL_DODGER_BLUE = '#219DFF',
  COLOR_ADDITIONAL_WHITE = '#FFFFFF',
  COLOR_ADDITIONAL_VIOLET = '#D6B4FC',
}
