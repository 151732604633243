import { StringDate } from '../utils/date/StringDate';
import { Founder } from './founders';
import { axiosRequest } from './instance';
import { Mentor, MentorId } from './mentors';

export interface Group {
  id: string;
  tenantId: string;
  groupName: string;
  country: string;
  state: string;
  city: string;
  description: string;
  valid: boolean;
}

export interface GatheringPeriod {
  periodType: 'CUSTOM_WEEK_DAYS' | 'MONTHLY' | 'ALL_WORK_DAYS' | 'DAILY' | '';
  dayOfWeek: number;
  dayOfMonth: number;
  ordinalNumber: number;
  skipWeeks: number;
  skipDays: number;
  stopAfterIterations: number;
  stopAfterDate?: string | Date | null;
  valid?: boolean;
}

export interface Gathering {
  id: string;
  tenantId: string;
  groupId: string;
  tenantEventChannelId: string;
  physicalLocation?: string | null;
  periodString?: string | null;
  status: string;
  attendanceCode: string;
  name: string;
  comments: string;
  externalInvitees: string;
  start: string;
  end: string;
  audience: string;
  dynamicInvite: boolean;
  listOfMentors: string[];
  listOfFounders: string[];
  valid: boolean;
  gatheringPeriod: GatheringPeriod;
}

export interface GatheringNew {
  tenantEventChannelId: string | null;
  physicalLocation?: string | null;
  periodString?: string | null;
  status: string;
  comments: string;
  start: string;
  end: string;
  audience: string;
  name: string;
  gatheringPeriod?: GatheringPeriod;
}

export interface GatheringDetails {
  tenantEventChannelId: string | null;
  physicalLocation?: string | null;
  periodString?: string | null;
  status: string;
  comments: string;
  start: string;
  end: string;
  audience: string;
  name: string;
  tenantId: string;
  id: string;
  groupId: string;
  gatheringPeriod?: GatheringPeriod;
  externalInvitees: string;
}

export interface GatheringOperationResponse {
  groupId: string;
  gatherings: Gathering[];
  eventChannelId: string;
  success: boolean;
}

export interface GatheringDuplicateInfo {
  start: string;
  end: string;
  status: string;
  tenantEventChannelId: string;
  comments: string;
  audience: string;
}

export interface GatheringNote {
  id: string;
  gatheringId: string;
  attachmentRefs: string;
  contents: string;
  creationDate: StringDate;
}

export interface GatheringNewNote {
  gatheringId: string;
  contents: string;
  attachmentRefs?: string | null;
  creationDate?: StringDate;
}

export interface GatheringFounder {
  id: string;
  gatheringId: string;
  founderId: string;
  valid: boolean;
}

export interface GatheringMentor {
  id: string;
  gatheringId: string;
  mentorId: string;
  valid: boolean;
}

export interface MentorGatheringAttendance {
  id: string;
  tenantId: string;
  creationDate: string;
  mentorId: string;
  gatheringId: string;
}

export interface GatheringMentorConfirmation {
  confirmationDate: string;
  mentor: Mentor;
}

interface GatheringFilter {
  status?: string;
  audience?: string;
  startDate?: string;
  endDate?: string;
}

const gatheringsAPI = {
  async getGatherings(page = 0) {
    try {
      const res = await axiosRequest.get<Gathering[]>({
        url: `/gatherings/pages/${page}`,
        credentials: true,
      });

      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async createGatheringGroups(gatherinDetails: GatheringNew) {
    try {
      const res = await axiosRequest.post<GatheringOperationResponse>({
        url: '/gatherings/groups',
        data: gatherinDetails,
        credentials: true,
      });

      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  async getGathering(gatheringId: string) {
    try {
      const res = await axiosRequest.get<Gathering>({
        url: `/gatherings/${gatheringId}`,
        credentials: true,
      });

      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async searchByFilter({
    status,
    audience,
    startDate,
    endDate,
  }: GatheringFilter = {}) {
    const res = await axiosRequest.get<Gathering[]>({
      url: `/gatherings/filters/`,
      data: { status, audience, startDate, endDate },
      credentials: true,
    });
    return res.data;
  },
  async changeCurrentGathering(gatheringDetails: GatheringDetails) {
    try {
      const res = await axiosRequest.put<GatheringOperationResponse>({
        url: '/gatherings',
        data: gatheringDetails,
        credentials: true,
      });

      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async changeAllGatheringForGroup(gatheringDetails: GatheringDetails) {
    try {
      const res = await axiosRequest.put<GatheringOperationResponse>({
        url: '/gatherings/groups',
        data: gatheringDetails,
        credentials: true,
      });

      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getGroupList(proupId: Gathering['groupId']) {
    try {
      const res = await axiosRequest.get<string[]>({
        url: `/gatherings/groups/${proupId}`,
        credentials: true,
      });

      return res.data;
    } catch (e: any) {
      if (e?.response?.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async search(searchString: string) {
    const res = await axiosRequest.get<Gathering[]>({
      url: `/gatherings/search/${searchString}`,
      credentials: true,
    });
    return res.data;
  },
  async cancelGroup(groupId: Gathering['groupId']) {
    try {
      const res = await axiosRequest.delete<GatheringOperationResponse>({
        url: `/gatherings/groups/${groupId}/invites`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async deleteGroup(groupId: Gathering['groupId']) {
    try {
      const res = await axiosRequest.delete<GatheringOperationResponse>({
        url: `/gatherings/groups/${groupId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async deleteGathering(gatheringId: Gathering['id']) {
    try {
      const res = await axiosRequest.delete<GatheringOperationResponse>({
        url: `/gatherings/${gatheringId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async cancelGathering(gatheringId: Gathering['id']) {
    try {
      const res = await axiosRequest.delete<GatheringOperationResponse>({
        url: `/gatherings/${gatheringId}/invites`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async sendInvitesGroup(groupId: Gathering['groupId']) {
    try {
      const res = await axiosRequest.post<GatheringOperationResponse>({
        url: `/gatherings/groups/${groupId}/invites`,
        credentials: true,
      });
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  async sendInvitesGathering(gatheringId: Gathering['id']) {
    try {
      const res = await axiosRequest.put<GatheringOperationResponse>({
        url: `/gatherings/${gatheringId}/invites`,
        credentials: true,
      });
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  async getNotes(gatheringId: Gathering['id']) {
    try {
      const res = await axiosRequest.get<GatheringNote[]>({
        url: `/gatherings/${gatheringId}/notes`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async removeNote(
    gatheringId: Gathering['id'],
    gatheringNoteId: GatheringNote['id'],
  ) {
    try {
      const res = await axiosRequest.delete<string>({
        url: `/gatherings/${gatheringId}/notes/${gatheringNoteId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async updateNote(note: GatheringNote) {
    try {
      const res = await axiosRequest.put<GatheringNote>({
        url: '/gatherings/notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async createNote(note: GatheringNewNote) {
    try {
      const res = await axiosRequest.post<GatheringNote>({
        url: '/gatherings/notes',
        data: note,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async groupsAutomaticSubscription(groupId: Gathering['groupId']) {
    try {
      await axiosRequest.post<GatheringOperationResponse>({
        url: `/gatherings/groups/${groupId}/automatic-subscription`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async groupsManualSubscription(groupId: Gathering['groupId']) {
    try {
      await axiosRequest.post<GatheringOperationResponse>({
        url: `/gatherings/groups/${groupId}/manual-subscription`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async getGatheringFounder(gatheringId: Gathering['id']) {
    try {
      const res = await axiosRequest.get<GatheringFounder[]>({
        url: `/gatherings/${gatheringId}/founders`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getGatheringFounderList(
    gatheringId: Gathering['id'],
    pageId: number = 0,
  ) {
    try {
      const res = await axiosRequest.get<Founder[]>({
        url: `/gatherings/${gatheringId}/founders/details/${pageId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async getGatheringMentorList(
    gatheringId: Gathering['id'],
    pageId: number = 0,
  ) {
    try {
      const res = await axiosRequest.get<Mentor[]>({
        url: `/gatherings/${gatheringId}/mentors/details/${pageId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async assignsFounder(gatheringId: Gathering['id'], founderId: Founder['id']) {
    try {
      await axiosRequest.post<GatheringFounder>({
        url: `/gatherings/${gatheringId}/founders/${founderId}`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async assignsFounderGroup(
    groupId: Gathering['groupId'],
    founderId: Founder['id'],
  ) {
    try {
      await axiosRequest.post<GatheringFounder>({
        url: `/gatherings/groups/${groupId}/founders/${founderId}`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async assignsMentor(gatheringId: Gathering['id'], mentorId: Mentor['id']) {
    try {
      await axiosRequest.post<GatheringMentor>({
        url: `/gatherings/${gatheringId}/mentors/${mentorId}`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async assignsMentorGroup(
    groupId: Gathering['groupId'],
    mentorId: Mentor['id'],
  ) {
    try {
      await axiosRequest.post<GatheringMentor>({
        url: `/gatherings/groups/${groupId}/mentors/${mentorId}`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async removeFounderAssignment(
    gatheringId: Gathering['id'],
    founderId: Founder['id'],
  ) {
    try {
      await axiosRequest.delete<GatheringFounder>({
        url: `/gatherings/${gatheringId}/founders/${founderId}`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async removeFounderAssignmentGroup(
    groupId: Gathering['groupId'],
    founderId: Founder['id'],
  ) {
    try {
      await axiosRequest.delete<GatheringFounder>({
        url: `/gatherings/groups/${groupId}/founders/${founderId}`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async removeMentorAssignment(
    gatheringId: Gathering['id'],
    mentorId: MentorId,
  ) {
    try {
      await axiosRequest.delete<GatheringMentor>({
        url: `/gatherings/${gatheringId}/mentors/${mentorId}`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async removeMentorAssignmentGroup(
    groupId: Gathering['groupId'],
    mentorId: MentorId,
  ) {
    try {
      await axiosRequest.delete<GatheringMentor>({
        url: `/gatherings/groups/${groupId}/mentors/${mentorId}`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async assignsAllFounders(gatheringId: Gathering['id']) {
    try {
      await axiosRequest.post<GatheringFounder>({
        url: `/gatherings/${gatheringId}/founders/all`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async assignsAllFoundersGroup(groupId: Gathering['groupId']) {
    try {
      await axiosRequest.post<GatheringOperationResponse>({
        url: `/gatherings/groups/${groupId}/founders/all`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async assignsAllMentors(gatheringId: Gathering['id']) {
    try {
      await axiosRequest.post<GatheringMentor>({
        url: `/gatherings/${gatheringId}/mentors/all`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async assignsAllMentorsGroup(groupId: Gathering['groupId']) {
    try {
      await axiosRequest.post<GatheringMentor>({
        url: `/gatherings/groups/${groupId}/mentors/all`,
        credentials: true,
      });

      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async attachFileToNote(gatheringId: Gathering['id'], file: File) {
    const formData = new FormData();
    formData.append('name', file.name);
    formData.append('file', file);

    const res = await axiosRequest.post<string>({
      url: `/files/gatherings/${gatheringId}/notes`,
      data: formData,
      credentials: true,
    });
    return res.data;
  },
  async foundersReplication(gatheringId: Gathering['id']) {
    try {
      await axiosRequest.post<GatheringOperationResponse>({
        url: `/gatherings/${gatheringId}/founders/replication`,
        credentials: true,
      });
      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async mentorsReplication(gatheringId: Gathering['id']) {
    try {
      await axiosRequest.post<GatheringOperationResponse>({
        url: `/gatherings/${gatheringId}/mentors/replication`,
        credentials: true,
      });
      return true;
    } catch (error: any) {
      throw error;
    }
  },
  async getMentorsAttendanceList(
    gatheringId: Gathering['id'],
    pageId: number = 0,
  ) {
    try {
      const res = await axiosRequest.get<GatheringMentorConfirmation[]>({
        url: `/gatherings/${gatheringId}/mentors/attendance/details/${pageId}`,
        credentials: true,
      });
      return res.data;
    } catch (e: any) {
      if (e.response.status === 404) {
        return [];
      }
      throw e;
    }
  },
  async addAttendance(gatheringId: Gathering['id'], mentorEmail: string) {
    try {
      const res = await axiosRequest.post<MentorGatheringAttendance>({
        url: `/gatherings/${gatheringId}/mentors/attendance/${mentorEmail}`,
        credentials: true,
      });
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
  async removeAttendance(gatheringId: Gathering['id'], mentorEmail: string) {
    try {
      const res = await axiosRequest.delete<MentorGatheringAttendance>({
        url: `/gatherings/${gatheringId}/mentors/attendance/${mentorEmail}`,
        credentials: true,
      });
      return res.data;
    } catch (error: any) {
      throw error;
    }
  },
};

export default gatheringsAPI;
