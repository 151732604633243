import {
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { add } from 'date-fns';
import { isT } from 'fp-utilities';
import { useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { Field, Form } from 'react-final-form';
import { SchedulingWizard } from '../../api/schedulingWizard';
import { TenantTimeZone } from '../../api/tenants/types/Settings';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { formatDateToRFC, isValidateDate } from '../../utils/date';
import { ZonedDate } from '../../utils/date/ZonedDate';
import { lengthField } from '../../utils/form';
import { validateEmail } from '../../utils/functions';
import { Button, FormButtons, FormGroup, Text } from '../common';
import { DatesOutOfRange } from '../common/Confirmation/DatesOutOfRange';
import {
  FormDateTimeInput,
  FormRadioboxGroup,
  TextFieldSummary,
  TextFieldWrapper,
} from './wrappers';

const useStyles = makeStyles((theme) => ({
  formBlocks: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  formContainer: {
    width: '100%',
  },
  formButtons: {
    justifyContent: 'flex-start',
  },
  marginTopField: {
    marginTop: '32px !important',
  },
  containerDateField: {
    width: '50%',
    '@media (max-width: 700px)': {
      width: 'calc(100% - 16px)',
    },
  },
  containerDateFieldFullWidth: {
    width: 'calc(100% - 16px)',
  },
  containerText: {
    marginTop: 15,
  },
  mentorsList: {
    marginTop: 15,
    marginBottom: 20,
  },
  dateInputContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr min-content',
    alignItems: 'center',
    gap: 8,
    width: '100%',
    marginTop: '32px !important',
  },
}));

type MeetingType = 'virtually' | 'in-person' | 'hybrid';

export type FormValues = {
  email: string;
  venture: string;
  physicalLocation: string | undefined;
  meeting: MeetingType;
} & { [k in `date-${number}`]: string };

export interface FormInitialValues {
  venture: string;
  meeting: MeetingType;
  physicalLocation: string | undefined;
  email?: string;
}

export interface ParsedFormValues {
  email: string;
  venture: string;
  arrayDate: string[];
  meeting: MeetingType;
  physicalLocation: string | undefined;
}

interface Props {
  onSubmit: (values: ParsedFormValues) => void;
  loading: boolean;
  initialValues?: FormInitialValues;
  mentors?: SchedulingWizard['mentorList'];
  timeZone: TenantTimeZone;
  noEmail?: boolean;
  noSubmit?: boolean;
  dateFullWidth?: boolean;
}

type Errors = {
  [K in keyof any]?: string;
};

const parsedFormValues = (
  value: FormValues,
  arr: number[],
): ParsedFormValues => {
  const arrayDate = arr
    .map((data) => {
      if (value[`date-${data}`]) {
        return value[`date-${data}`];
      }
      return undefined;
    })
    .filter(isT);

  return {
    email: value.email,
    venture: value.venture,
    arrayDate,
    meeting: value.meeting,
    physicalLocation: value.physicalLocation,
  };
};

const SchedulingWizardInitialForm = forwardRef(
  (
    {
      onSubmit,
      loading,
      initialValues,
      mentors,
      timeZone,
      noEmail,
      noSubmit,
      dateFullWidth,
    }: Props,
    ref,
  ) => {
    const formRef = useRef<Function | null>(null);

    const classes = useStyles();
    const { rb } = useResourceBundles();
    const isSubmitDisabled = loading;
    const [countInputDate, setCountInputDate] = useState([0, 1, 2, 3]);
    const counterRef = useRef(4);
    const min = 4;
    const max = 10;

    const validateForm = (values: FormValues) => {
      const errors: Errors = {};
      if (!values.email) {
        errors.email = 'Required';
      } else if (!validateEmail(values.email)) {
        errors.email = 'Invalid';
      }
      countInputDate.forEach((dateIndex: number) => {
        if (
          !values[`date-${dateIndex}`] ||
          !isValidateDate(values[`date-${dateIndex}`])
        ) {
          errors[`date-${dateIndex}`] = 'Invalid';
        }
      });

      if (values.meeting === 'in-person' || values.meeting === 'hybrid') {
        if (!values.physicalLocation) {
          errors['physicalLocation'] = 'Required';
        } else if (values.physicalLocation.length > 2048) {
          errors['physicalLocation'] =
            'Address should be less then 2048 characters';
        }
      }

      return errors;
    };

    const handleSubmit = (values: FormValues) => {
      const parsedValues = parsedFormValues(values, countInputDate);
      onSubmit(parsedValues);
    };

    const getDates = (form: FormValues): ZonedDate<TenantTimeZone>[] => {
      return countInputDate
        .map((index) => form[`date-${index}`])
        .filter(isT)
        .map((v) => new ZonedDate(new Date(v), timeZone));
    };

    useImperativeHandle(
      ref,
      () => ({
        submit: formRef.current,
      }),
      [],
    );

    return (
      <DatesOutOfRange getDates={getDates} onSubmit={handleSubmit}>
        {({ onSubmit }) => {
          return (
            <Form
              validate={validateForm}
              onSubmit={onSubmit}
              initialValues={initialValues}
              keepDirtyOnReinitialize
              render={(formProps) => {
                formRef.current = formProps.handleSubmit;
                return (
                  <div className={classes.formBlocks}>
                    <form
                      id='scheduling-wizard-initial-form'
                      noValidate
                      className={classes.formContainer}>
                      <FormGroup>
                        <Field<string>
                          name='venture'
                          component={TextFieldSummary}
                          label='Venture'
                          disabled
                          testid='scheduling-wizard-initial-form-venture'
                        />
                      </FormGroup>

                      {mentors && mentors.length > 0 && (
                        <div className={classes.mentorsList}>
                          <Typography variant='h4'>
                            {rb('mentors-u')}
                          </Typography>
                          {mentors.map((mentor) => (
                            <div>
                              <Text variant='normal'>
                                {mentor.firstName} {mentor.lastName}
                              </Text>
                            </div>
                          ))}
                        </div>
                      )}

                      {!noEmail && (
                        <FormGroup>
                          <Field<string>
                            name='email'
                            component={TextFieldWrapper}
                            label='Email*'
                            formatOnBlur
                            format={(value: string) => {
                              return value ? value.toLowerCase() : value;
                            }}
                            testid='scheduling-wizard-initial-form-email'
                            InputProps={{
                              inputProps: {
                                maxLength: lengthField.email,
                              },
                            }}
                          />
                        </FormGroup>
                      )}

                      <Typography
                        variant='h4'
                        className={classes.marginTopField}>
                        How would you like to meet?
                      </Typography>
                      <Field<string>
                        name='meeting'
                        component={FormRadioboxGroup}
                        options={[
                          { value: 'virtually', label: 'Virtually' },
                          {
                            value: 'in-person',
                            label: 'In-person',
                          },
                          {
                            value: 'hybrid',
                            label: 'Hybrid',
                            info: 'This hybrid meeting will have both in-person and virtual channels',
                          },
                        ]}
                        testid='scheduling-wizard-initial-form-meeting'
                      />
                      {formProps.values.meeting === 'in-person' ||
                      formProps.values.meeting === 'hybrid' ? (
                        <Field<string>
                          name='physicalLocation'
                          component={TextFieldWrapper}
                          placeholder={'Default location (editable)'}
                          testid='scheduling-wizard-initial-form-physicalLocation'
                        />
                      ) : null}

                      <Typography
                        variant='h4'
                        className={classes.marginTopField}>
                        Please provide at least {min} dates/times for the
                        session: *
                      </Typography>
                      <div className={classes.containerText}>
                        <Text variant='normal'>
                          The closest date/time needs to be at least in 7 days
                          from today
                        </Text>
                      </div>

                      <div
                        className={
                          dateFullWidth
                            ? classes.containerDateFieldFullWidth
                            : classes.containerDateField
                        }>
                        <FormGroup column>
                          {countInputDate.map((value, index) => (
                            <div
                              key={index}
                              className={classes.dateInputContainer}>
                              <Field<string>
                                name={`date-${value}`}
                                testId='generateTomorrowDate'
                                component={FormDateTimeInput}
                                label='Next Session Date Start'
                                parse={(value) => {
                                  try {
                                    if (value) {
                                      return formatDateToRFC(value);
                                    }
                                    return '';
                                  } catch (e: any) {
                                    return 'invalid';
                                  }
                                }}
                                minDate={add(new Date(), { days: 7 })}
                              />
                              <IconButton
                                onClick={() => {
                                  setCountInputDate((prev) =>
                                    prev.filter((_, i) => i !== index),
                                  );
                                }}
                                disabled={countInputDate.length <= min}
                                data-testid={`form-note-delete-${index}`}>
                                <DeleteIcon />
                              </IconButton>
                            </div>
                          ))}
                        </FormGroup>
                      </div>
                      {countInputDate.length < max && (
                        <Button
                          data-testid='button-add-option'
                          className={classes.marginTopField}
                          onClick={() =>
                            setCountInputDate((prev) => [
                              ...prev,
                              counterRef.current++,
                            ])
                          }>
                          Add another option
                        </Button>
                      )}

                      {!noSubmit && (
                        <FormButtons className={classes.formButtons}>
                          <Button
                            data-testid='button-submit-form'
                            onClick={formProps.handleSubmit}
                            disabled={isSubmitDisabled}>
                            {loading ? (
                              <CircularProgress size={24} color='inherit' />
                            ) : (
                              'Submit'
                            )}
                          </Button>
                        </FormButtons>
                      )}
                    </form>
                  </div>
                );
              }}
            />
          );
        }}
      </DatesOutOfRange>
    );
  },
);

export default SchedulingWizardInitialForm;
