import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { parse as parseQuery } from 'query-string';
import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { useLocation } from 'react-router-dom';
import { forkJoin, from, of } from 'rxjs';
import _pick from 'lodash/pick';
import { catchError, map, tap } from 'rxjs/operators';
import foundersAPI, { FounderId } from '../../../api/founders';
import reportsAPI from '../../../api/reports';
import schedulingWizardApi from '../../../api/schedulingWizard';
import { useAuth } from '../../../hooks';
import { Email } from '../../../utils/String/Email';
import { formatDateApi } from '../../../utils/date';
import { decodeQuery } from '../../../utils/functions';
import { reducer } from './reducer';
import {
  CancelSchedule,
  ConfirmScheduleCheck,
  ConfirmScheduleCreate,
  ConfirmScheduleDelete,
  loadError,
  loadSuccess,
  ResetFilters,
  scheduleCheckSuccess,
  scheduleDeleteSuccess,
  scheduleCreateSuccess,
  ScheduleDeleteSuccess,
  scheduleError,
  SetScheduleVentureId,
  ShowScheduleCheck,
  SetFilterDateRange,
} from './types/Actions';
import { loading, LoadingPayload, State } from './types/State';

interface SearchParams {
  [x: string]: any;
}

function getFilterBySearch(
  searchParams: SearchParams,
): LoadingPayload['filter'] {
  const decodedFilter = decodeQuery(searchParams.filter || '');
  const parsedQuery = _pick(decodedFilter || {}, [
    'dateRange',
  ]) as LoadingPayload['filter'];

  if (parsedQuery.dateRange) {
    parsedQuery.dateRange = {
      startDate: new Date(parsedQuery.dateRange.startDate),
      endDate: new Date(parsedQuery.dateRange.endDate),
    };
  }

  return parsedQuery as LoadingPayload['filter'];
}

function getSearchParams(search: string): SearchParams {
  return parseQuery(search);
}

function getDefaultDateRange(): { startDate: Date; endDate: Date } {
  const today = new Date();
  const startDate = new Date(today);
  const endDate = new Date(today);

  startDate.setDate(today.getDate() - 2);

  endDate.setDate(today.getDate() + 28);

  return { startDate, endDate };
}

export function useFounderSessionsPage(): [
  State,
  Dispatch<
    | ResetFilters
    | ShowScheduleCheck
    | CancelSchedule
    | SetScheduleVentureId
    | ConfirmScheduleCheck
    | ConfirmScheduleDelete
    | ScheduleDeleteSuccess
    | ConfirmScheduleCreate
    | SetFilterDateRange
  >,
] {
  const { tokenData } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();

  const filter = useMemo((): LoadingPayload['filter'] => {
    const searchParams = getSearchParams(location.search);
    const queryDateRange = getFilterBySearch(searchParams).dateRange;
    const defaultDateRange = getDefaultDateRange();

    return {
      dateRange: queryDateRange || defaultDateRange,
    };
  }, [location.search]);

  const [state, dispatch] = useReducer(
    reducer,
    loading({
      id: tokenData?.identityid as FounderId,
      email: tokenData?.email as Email,
      filter,
      scheduleVentureId: undefined,
    }),
  );

  useEffect(() => {
    if (state.type === 'Loading') {
      const s = forkJoin({
        sessions: from(
          foundersAPI.getEventsDetailsByDataRange(
            state.payload.id,
            formatDateApi(state.payload.filter.dateRange.startDate),
            formatDateApi(state.payload.filter.dateRange.endDate),
          ),
        ),
        ventures: from(reportsAPI.getDashboardFounders()).pipe(
          map((r) =>
            r.dashlets.map((d) => ({
              id: d.venture.id,
              name: d.venture.ventureName,
            })),
          ),
        ),
      })
        .pipe(
          map(loadSuccess),
          catchError((e: AxiosError) =>
            of(
              loadError(e.response?.data?.message ?? 'Something went wrong'),
            ).pipe(
              tap(
                (a) =>
                  a.payload !== 'No ventures found for the founder' &&
                  enqueueSnackbar(a.payload, { variant: 'error' }),
              ),
            ),
          ),
        )
        .subscribe(dispatch);
      return () => s.unsubscribe();
    }
  }, [
    enqueueSnackbar,
    state.payload.filter.dateRange.endDate,
    state.payload.filter.dateRange.startDate,
    state.payload.id,
    state.type,
  ]);

  useEffect(() => {
    if (state.type === 'ScheduleChecking') {
      const s = from(
        schedulingWizardApi.ventureActiveWizards(
          state.payload.scheduleVentureId,
        ),
      )
        .pipe(
          map(scheduleCheckSuccess),
          catchError((e: AxiosError) =>
            of(
              scheduleError(
                e.response?.data?.message ?? 'Something went wrong',
              ),
            ).pipe(
              tap((a) => enqueueSnackbar(a.payload, { variant: 'error' })),
            ),
          ),
        )
        .subscribe(dispatch);
      return () => s.unsubscribe();
    }
  }, [enqueueSnackbar, state.payload.scheduleVentureId, state.type]);

  useEffect(() => {
    if (state.type === 'ScheduleDeleting') {
      const s = from(
        schedulingWizardApi.cancel(state.payload.currentWizzards[0].id),
      )
        .pipe(
          map(scheduleDeleteSuccess),
          catchError((e: AxiosError) =>
            of(
              scheduleError(
                e.response?.data?.message ?? 'Something went wrong',
              ),
            ).pipe(
              tap((a) => enqueueSnackbar(a.payload, { variant: 'error' })),
            ),
          ),
        )
        .subscribe(dispatch);
      return () => s.unsubscribe();
    }
  }, [enqueueSnackbar, state.payload, state.type]);

  useEffect(() => {
    if (state.type === 'ScheduleCreating') {
      const { email, scheduleCreateValues, scheduleVentureId } = state.payload;
      const s = from(
        schedulingWizardApi.createByFounder({
          ventureId: scheduleVentureId,
          originatorEmail: email,
          schedulingWizardDates: scheduleCreateValues.arrayDate.map((date) => ({
            startDateTime: date,
            endDateTime: date,
          })),
          schedulingWizardFounders: [],
          schedulingWizardMentors: [],
          physicalLocation:
            scheduleCreateValues.meeting === 'in-person' ||
            scheduleCreateValues.meeting === 'hybrid'
              ? scheduleCreateValues.physicalLocation || null
              : null,
          virtualChannelNeeded:
            scheduleCreateValues.meeting === 'virtually' ||
            scheduleCreateValues.meeting === 'hybrid',
        }),
      )
        .pipe(
          map(scheduleCreateSuccess),
          tap(() =>
            enqueueSnackbar(
              'Scheduling process started successfully!\nNew session(s) will be scheduled in 4 days.',
              {
                variant: 'success',
              },
            ),
          ),
          catchError((e: AxiosError) =>
            of(
              scheduleError(
                e.response?.data?.message ?? 'Something went wrong',
              ),
            ).pipe(
              tap((a) => enqueueSnackbar(a.payload, { variant: 'error' })),
            ),
          ),
        )
        .subscribe(dispatch);
      return () => s.unsubscribe();
    }
  }, [enqueueSnackbar, state.payload, state.type]);

  return [state, dispatch];
}
