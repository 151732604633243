import { AxiosError } from 'axios';
import { parse } from 'query-string';
import { Dispatch, useEffect, useMemo, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router';
import { from, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import schedulingWizardApi from '../../../api/schedulingWizard';
import { getRoutePath, Pages } from '../../../router/constants';
import { unreachableError } from '../../../utils/unreachableError';
import { reducer } from './reducer';
import * as Actions from './types/Actions';
import { invalidKey, ready, State } from './types/State';

export function useCancelPage(): [State, Dispatch<Actions.Actions>] {
  const history = useHistory();
  const { search } = useLocation();
  const key: string | undefined = useMemo(() => {
    const v = parse(search).key;

    return typeof v === 'string' ? v : undefined;
  }, [search]);

  const [state, dispatch] = useReducer(
    reducer,
    key ? ready({ key }) : invalidKey(),
  );

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    switch (state.type) {
      case 'Cancelling': {
        const loading$ = from(
          schedulingWizardApi.cancelWithKey(state.payload.key),
        )
          .pipe(
            map((response) => Actions.cancellingSuccess(response.ventureId)),
            catchError((e: AxiosError) =>
              of(Actions.cancellingError(e.response?.data.message)),
            ),
          )
          .subscribe(dispatch);
        return () => {
          loading$.unsubscribe();
        };
      }
      case 'Ready':
      case 'InvalidKey':
      case 'CancellingError':
        break;
      case 'Declined':
        timeout = setTimeout(() => {
          history.push(Pages.DASHBOARD);
        }, 3000);
        break;
      case 'CancellingSuccess': {
        timeout = setTimeout(() => {
          if (state.payload.ventureId) {
            history.push(
              getRoutePath(Pages.VENTURE_DETAILS, {
                ventureId: state.payload.ventureId,
              }),
            );
          } else {
            history.push(Pages.DASHBOARD);
          }
        }, 3000);
        break;
      }
      default:
        unreachableError(state);
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.type]);

  return [state, dispatch];
}
