import { AxiosError } from 'axios';
import { Email } from '../utils/String/Email';
import { Founder, FounderId } from './founders';
import { axiosRequest } from './instance';
import { Mentor, MentorId } from './mentors';
import { TenantId } from './tenants/Tenant';
import { VentureId } from './ventures/types/Venture';

declare const schedulingWizardId: unique symbol;

export type SchedulingWizardId = string & {
  [schedulingWizardId]: 'SchedulingWizardId';
};

export interface SchedulingWizard {
  id: SchedulingWizardId;
  tenantId: TenantId;
  ventureId: string;
  creationDate: string;
  status: string;
  comments: string;
  originatorEmail: string;
  votesNumber: number;
  schedulingWizardDates: SchedulingWizardDate[];
  schedulingWizardFounders: SchedulingWizardFounder[];
  schedulingWizardMentors: SchedulingWizardMentor[];
  failureReason: string;
  selectedTimeSlotIds: string[];
  founderList: Founder[];
  mentorList: Mentor[];
  ventureName: string;
  key: string;
  physicalLocation: string | null;
  virtualChannelNeeded: boolean;
}

export interface NewSchedulingWizard {
  ventureId: VentureId;
  originatorEmail: Email;
  schedulingWizardDates: Omit<SchedulingWizardDate, 'schedulingWizardId'>[];
  schedulingWizardMentors: { mentorId: MentorId }[];
  schedulingWizardFounders: { founderId: FounderId }[];
  physicalLocation: string | null;
  virtualChannelNeeded: boolean;
}

export interface SchedulingWizardNewSlots {
  id: string;
  tenantId: string;
  ventureId: string;
  originatorEmail: string;
  schedulingWizardDates: SchedulingWizardDate[];
  physicalLocation: string | null;
  key: string;
  virtualChannelNeeded: boolean;
}

export interface SchedulingWizardNewVote {
  id: string;
  tenantId: string;
  ventureId: string;
  originatorEmail: string;
  selectedTimeSlotIds: string[];
  key: string;
}

export interface SchedulingWizardDate {
  id?: string;
  schedulingWizardId: string;
  startDateTime: string;
  endDateTime: string;
  mentorsVoted?: string;
}

export interface SchedulingWizardFounder {
  id: string;
  schedulingWizardId: string;
  founderId: string;
}

export interface SchedulingWizardMentor {
  id: string;
  schedulingWizardId: string;
  mentorId: string;
}

const schedulingWizardApi = {
  async create(ventureId: string) {
    try {
      const res = await axiosRequest.post<SchedulingWizard>({
        url: '/schedulingwizard',
        credentials: true,
        data: {
          ventureId,
        },
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async createByMentor(data: NewSchedulingWizard) {
    try {
      const res = await axiosRequest.post<SchedulingWizard>({
        url: '/schedulingwizard/initiations/bymentor',
        credentials: true,
        data,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async createByFounder(data: NewSchedulingWizard) {
    try {
      const res = await axiosRequest.post<SchedulingWizard>({
        url: '/schedulingwizard/initiations/byfounder',
        credentials: true,
        data,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async createByAdmin(data: NewSchedulingWizard) {
    try {
      const res = await axiosRequest.post<SchedulingWizard>({
        url: '/schedulingwizard/initiations/byadmin',
        credentials: true,
        data,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async getSchedulingWizard(key: string) {
    try {
      const res = await axiosRequest.get<SchedulingWizard>({
        url: `/schedulingwizard/keys/${key}`,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async schedulingWizardVotes(value: SchedulingWizardNewVote) {
    try {
      const res = await axiosRequest.post<SchedulingWizard>({
        url: '/schedulingwizard/votes',
        data: value,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async schedulingWizardSlots(value: SchedulingWizardNewSlots) {
    try {
      const res = await axiosRequest.post<SchedulingWizard>({
        url: '/schedulingwizard/slots',
        data: value,
      });
      return res.data;
    } catch (e: any) {
      throw e;
    }
  },
  async ventureActiveWizards(
    ventureId: VentureId,
  ): Promise<SchedulingWizard[]> {
    try {
      const res = await axiosRequest.get<SchedulingWizard[]>({
        url: `/schedulingwizard/ventures/${ventureId}`,
        credentials: true,
      });
      return res.data;
    } catch (e) {
      if ((e as AxiosError).response?.status === 404) {
        return [];
      }

      throw e;
    }
  },
  async cancel(id: SchedulingWizardId): Promise<SchedulingWizard> {
    const res = await axiosRequest.delete<SchedulingWizard>({
      url: `/schedulingwizard/ids/${id}`,
      credentials: true,
    });
    return res.data;
  },
  async cancelWithKey(key: string): Promise<SchedulingWizard> {
    const res = await axiosRequest.delete<SchedulingWizard>({
      url: `/schedulingwizard/keys/${key}`,
    });
    return res.data;
  },
};

export default schedulingWizardApi;
