import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import cn from 'classnames';
import { useSnackbar } from 'notistack';
import { parse as parseQuery } from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import _pick from 'lodash/pick';
import eventsAPI, {
  EventFullDetails,
  EventResponseForm,
} from '../../api/events';
import { getSettings } from '../../api/tenants';
import { TenantTimeZone } from '../../api/tenants/types/Settings';
import venturesAPI from '../../api/ventures';
import {
  Button,
  Dialog,
  FormButtons,
  SnackMessage,
  Text,
} from '../../components/common';
import WarningBeforeStart from '../../components/common/Confirmation/WarningBeforeStart';
import ReportLeadMentorForm, {
  FormInitialValues,
  FormValues,
} from '../../components/forms/report-lead-mentor-form';
import { useResourceBundles } from '../../contexts/resource-bundles-context';
import { COLORS } from '../../theme/variables';
import { getTenantId } from './utils';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: 'calc(100% - 60px)',
      padding: '50px 30px',
    },

    [theme.breakpoints.up('md')]: {
      width: '100%',
      padding: '50px 0',
    },
  },
  block: {
    width: '700px',
    padding: '32px',
    background: '#FFFFFF',
    boxShadow:
      '0px 0px 2px rgba(34, 91, 187, 0.16), 0px 4px 8px rgba(51, 126, 255, 0.04), 0px 8px 16px rgba(51, 126, 255, 0.04)',
    borderRadius: 0,
    boxSizing: 'border-box',

    [theme.breakpoints.up('xs')]: {
      borderRadius: '16px',
    },
  },
  loadingBlock: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loadingContainer: {
    height: '100vh',
    padding: 0,

    [theme.breakpoints.up('xs')]: {
      width: '100%',
    },
  },
  successBlock: {
    width: 300,
    minHeight: 180,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'start',
    borderRadius: '16px',
  },
  successIcon: {
    fontSize: 30,
    color: COLORS.COLOR_GREEN_BASE,
    marginBottom: 15,
  },
  errorIcon: {
    fontSize: 30,
    color: COLORS.COLOR_RED_BASE,
    marginBottom: 15,
  },
  successText: {
    textAlign: 'center',
  },
  title: {
    marginBottom: 15,
  },
  formButtons: {
    alignItems: 'flex-end',
    flexGrow: 1,
  },
}));

function parseKey(search: string): string | undefined {
  const { key } = _pick(parseQuery(search), ['key']);

  return typeof key === 'string' ? key : undefined;
}

const formatValuesRequest = (
  values: FormValues,
  fullDetails: EventFullDetails,
): EventResponseForm => {
  return {
    tenantId: fullDetails.event.tenantId,
    eventId: fullDetails.event.id,
    ventureId: fullDetails.event.ventureId,
    sessionRating: +values.sessionRating,
    ventureProgressRating: +values.ventureProgressRating,
    attendingMentors: values.attendingMentors,
    attendingFounders: values.attendingFounders,
    mostImpactfulMentor: values?.mostImpactfulMentor || '',
    submitterEmail: values.submitterEmail,
    mentorReport: values.mentorReport || '',
    mentorReportAttachments: values.attachments || '',
    mentorReportNotes: values.mentorReportNotes || '',
    founderNotes: '',
    founderOtherNotes: '',
    founderNotesAttachments: '',
    agendaQuestion1: '',
    agendaQuestion2: '',
    agendaQuestion3: '',
    agendaQuestion4: '',
    agendaAttachments: '',
    isLeadMentorReport: true,
    nextEventStart: values.nextEventStart,
    nextEventEnd: values.nextEventEnd,
    isMentorReport: false,
    isFounderReport: false,
    founderReport: false,
    agendaPresented: false,
    physicalLocationRequested:
      values.meeting === 'in-person' || values.meeting === 'hybrid'
        ? values.physicalLocation
        : undefined,
    virtualChannelRequested:
      values.meeting === 'virtually' || values.meeting === 'hybrid',
  };
};

function formatValueDateRequest(values: FormValues, fullDetails: Payload) {
  return {
    email: values.submitterEmail,
    event: {
      ...fullDetails.event,
      start: values.nextEventStart,
      end: values.nextEventEnd,
      physicalLocation:
        values.meeting === 'in-person' ? values.physicalLocation ?? null : null,
    },
  };
}

const getInitialValues = (fullDetails: Payload): FormInitialValues => {
  const mentorListId =
    fullDetails?.mentorList?.length > 0
      ? fullDetails?.mentorList.map((mentor) => mentor.id)
      : [];

  const founderListId =
    fullDetails?.founderList?.length > 0
      ? fullDetails?.founderList.map((founder) => founder.id)
      : [];

  return {
    submitterEmail: '',
    summary: fullDetails?.event.summary || '',
    start: fullDetails?.event.start || '',
    end: fullDetails?.event.end || '',
    sessionRating: 0,
    ventureProgressRating: 0,
    attendingMentors: mentorListId,
    attendingFounders: founderListId,
    mostImpactfulMentor: '',
    mentorReport: '',
    mentorReportNotes: '',
    nextEventStart: '',
    nextEventEnd: '',
    rateSession: 0,
    rateVenture: 0,
    attachments: [],
    physicalLocation: fullDetails.defaultLocation,
    meeting: 'virtually',
  };
};

interface Payload extends EventFullDetails {
  leadingMentor: boolean;
  defaultLocation: string | undefined;
  tenantTimezone: TenantTimeZone;
  reportBasedSchedulingEnabled: boolean;
}

function ReportPage() {
  const classes = useStyles();
  const location = useLocation();
  const { rb } = useResourceBundles();

  const [fullDetails, setFullDetails] = useState<Payload>();
  const [statusForm, setStatusForm] = useState<
    'success' | 'loading' | 'error' | ''
  >('loading');
  const [isLoadingSaveForm, setIsLoadingSaveForm] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [error, setError] = useState('');
  const [errorVaildDate, setErrorVaildDate] = useState(false);
  const [prevValue, setPrevValue] = useState<FormValues>();
  const [openModal, setOpenModal] = useState(false);
  const [modalText, setModalText] = useState('');

  const key = useMemo(() => parseKey(location.search), [location.search]);

  const optionsMentor = useMemo(() => {
    return fullDetails?.mentorList && fullDetails?.mentorList?.length > 0
      ? fullDetails?.mentorList.map((mentor) => {
          return {
            value: mentor.id,
            label: `${mentor.firstName} ${mentor.lastName}`,
          };
        })
      : [];
  }, [fullDetails?.mentorList]);

  const initialValues = useMemo(() => {
    if (fullDetails) {
      return getInitialValues(fullDetails);
    }
    return undefined;
  }, [fullDetails]);

  const handleSubmit = async (values: FormValues) => {
    const valuesRequest = formatValuesRequest(
      values,
      fullDetails as EventFullDetails,
    );

    let valueValidDate;
    if (valuesRequest.nextEventStart && valuesRequest.nextEventEnd) {
      valueValidDate = formatValueDateRequest(values, fullDetails as Payload);
    }
    try {
      setIsLoadingSaveForm(true);
      if (valueValidDate) {
        const responseCheckValidation =
          await eventsAPI.eventsValidationAnonymous(valueValidDate);

        if (!responseCheckValidation.scheduled) {
          const newValue = {
            ...values,
            nextEventStart: '',
            nextEventEnd: '',
          };

          setPrevValue(newValue);
          setIsLoadingSaveForm(false);
          responseCheckValidation.message &&
            setModalText(responseCheckValidation.message);
          setOpenModal(true);
          return;
        }
      }

      await eventsAPI.eventsResponseForm(valuesRequest);
      setErrorVaildDate(false);
      setStatusForm('success');
      setIsLoadingSaveForm(false);
    } catch (e: any) {
      setIsLoadingSaveForm(false);
      const messageError = e.response?.data?.message || 'Internal server error';

      enqueueSnackbar('An error occurred while saving your report', {
        variant: 'error',
        content: (key, message) =>
          SnackMessage({
            key,
            message,
            variant: 'error',
            additionalMessage: messageError,
          }),
        style: { whiteSpace: 'pre-line' },
      });
    }
  };

  const loadDetails = async (key: string) => {
    const tenantId = getTenantId(key);

    if (!tenantId) {
      setError('Invalid key value');
      setStatusForm('error');
      return;
    }

    try {
      setStatusForm('loading');
      const responseDetails = await eventsAPI.getEventDetails(key);
      const {
        mostImpactfulMentorEnabled,
        defaultPhysicalLocation,
        timeZone,
        reportBasedSchedulingEnabled,
      } = await getSettings(tenantId);
      setFullDetails({
        ...responseDetails,
        leadingMentor: mostImpactfulMentorEnabled,
        defaultLocation: defaultPhysicalLocation ?? undefined,
        tenantTimezone: timeZone,
        reportBasedSchedulingEnabled,
      });
      setStatusForm('');
    } catch (e: any) {
      setError(e.response?.data?.message || 'Internal server error');
      setStatusForm('error');
    }
  };

  const handleUploadFile = useCallback(
    async (file: File) => {
      try {
        if (fullDetails?.event.ventureId) {
          const loadedFileURL = await venturesAPI.attachFileToReport(
            fullDetails.event.ventureId,
            fullDetails.event.tenantId,
            file,
          );
          return loadedFileURL;
        }
      } catch (e: any) {
        return '';
      }
    },
    [fullDetails],
  );

  const resendingForm = () => {
    if (prevValue) {
      handleSubmit(prevValue);
      setPrevValue(undefined);
    }
  };

  useEffect(() => {
    if (key) {
      loadDetails(key);
    } else {
      setError('Invalid key value');
      setStatusForm('error');
    }
  }, [key]);

  useEffect(() => {
    document.body.style.minWidth = 'auto';
  }, []);

  if (statusForm === 'loading') {
    return (
      <div
        data-testid='report-page'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.loadingBlock)}>
          <CircularProgress size={54} color='primary' />
        </div>
      </div>
    );
  }

  if (statusForm === 'success') {
    return (
      <div
        data-testid='report-page-success'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CheckCircleIcon className={classes.successIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>Success!</Text>
          </div>
        </div>
      </div>
    );
  }

  if (statusForm === 'error' || !fullDetails) {
    return (
      <div
        data-testid='report-page-error'
        className={cn(classes.container, classes.loadingContainer)}>
        <div className={cn(classes.block, classes.successBlock)}>
          <CancelIcon className={classes.errorIcon} />
          <div className={classes.successText}>
            <Text variant='normal'>
              Unfortunately an error occurred while loading the report. Please
              try again in a couple of minutes. If the problem persists please
              share the URL with the support team at support@tractionfive.com
              <br />
              Details: {error}
            </Text>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <WarningBeforeStart date={fullDetails.event.start} />
      <div data-testid='report-page' className={classes.container}>
        <div className={classes.block}>
          <Typography variant='h2' className={classes.title}>
            Lead {rb('mentor-u')} Report
          </Typography>
          <ReportLeadMentorForm
            initialValues={initialValues}
            fullDetails={fullDetails}
            onSubmit={handleSubmit}
            loading={isLoadingSaveForm}
            errorVaildDate={errorVaildDate}
            onUploadFile={handleUploadFile}
            optionsMentor={optionsMentor}
            leadMentorEnabled={fullDetails.leadingMentor}
            timeZone={fullDetails.tenantTimezone}
            reportBasedSchedulingEnabled={
              fullDetails.reportBasedSchedulingEnabled
            }
          />
        </div>
        <Dialog
          open={openModal}
          setOpen={setOpenModal}
          title='Chosen date/time is not available'
          width={500}
          contentRenderer={({ handleClose }) => (
            <div>
              <Text variant='normal'>
                {modalText ||
                  'The next meeting cannot be scheduled automatically for the date and time you selected as it was taken by one or more ventures.'}
              </Text>
              <br />
              <br />
              <Text variant='normal'>
                Please pick a different date or send the report without
                date/time.
              </Text>
              <br />
              <br />
              <FormButtons className={classes.formButtons} justify='center'>
                <Button onClick={handleClose}>
                  Pick a<br />
                  different time
                </Button>
                <Button
                  variant='outlined'
                  disabled={isLoadingSaveForm}
                  data-testid='mentor-assignment-apply'
                  onClick={() => resendingForm()}>
                  {isLoadingSaveForm ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    <>
                      Send report without the
                      <br />
                      next meeting date/time
                    </>
                  )}
                </Button>
              </FormButtons>
            </div>
          )}
        />
      </div>
    </>
  );
}

export default ReportPage;
