// region InvalidKey
export interface InvalidKey {
  type: 'InvalidKey';
}

export const invalidKey = (): InvalidKey => ({
  type: 'InvalidKey',
});
// endregion

// region Ready
export interface ReadyPayload {
  key: string;
}

export interface Ready {
  type: 'Ready';
  payload: ReadyPayload;
}

export const ready = (payload: Ready['payload']): Ready => ({
  type: 'Ready',
  payload,
});
// endregion

// region Declined
export interface Declined {
  type: 'Declined';
}

export const declined = (): Declined => ({
  type: 'Declined',
});
// endregion

// region Cancelling
export interface CancellingPayload extends ReadyPayload {}

export interface Cancelling {
  type: 'Cancelling';
  payload: CancellingPayload;
}

export const cancelling = (payload: Cancelling['payload']): Cancelling => ({
  type: 'Cancelling',
  payload,
});
// endregion

// region CancellingError
export interface CancellingErrorPayload extends CancellingPayload {
  message: string;
}

export interface CancellingError {
  type: 'CancellingError';
  payload: CancellingErrorPayload;
}

export const cancellingError = (
  payload: CancellingError['payload'],
): CancellingError => ({
  type: 'CancellingError',
  payload,
});
// endregion

// region CancellingSuccess
export interface CancellingSuccessPayload extends ReadyPayload {
  ventureId: string;
}

export interface CancellingSuccess {
  type: 'CancellingSuccess';
  payload: CancellingSuccessPayload;
}

export const cancellingSuccess = (
  payload: CancellingSuccess['payload'],
): CancellingSuccess => ({
  type: 'CancellingSuccess',
  payload,
});
// endregion

export type State =
  | InvalidKey
  | Ready
  | Declined
  | Cancelling
  | CancellingError
  | CancellingSuccess;
