import React, { useState, useCallback, useRef, useEffect } from 'react';
import venturesAPI from '../../api/ventures';
import { Venture } from '../../api/ventures/types/Venture';

interface InputRendererProps {
  value: string;
  onChange: (event: React.ChangeEvent<any>) => any;
  isLoading: boolean;
}

interface SelectVentureProps {
  id: string;
  ventureName: string;
}

interface VentureAutocompleteProps {
  inputRenderer: (props: InputRendererProps) => React.ReactNode;
  onSearch: (ventures: Venture[]) => any;
  selectVenture?: SelectVentureProps;
  iteration?: number;
  isMentorRelated?: boolean;
}

function VentureAutocomplete({
  inputRenderer,
  onSearch,
  selectVenture,
  iteration,
  isMentorRelated,
}: VentureAutocompleteProps) {
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const debouncedTimer = useRef<number>();
  const currentValue = useRef<string>(value);

  const searchVentures = async (ventureName: string) => {
    try {
      const foundedVentures = isMentorRelated
        ? await venturesAPI.mentorRelatedSearch(ventureName)
        : await venturesAPI.search(ventureName);
      setIsLoading(false);
      if (currentValue.current === ventureName) {
        onSearch(foundedVentures);
      }
    } catch (e: any) {
      setIsLoading(false);
      if (currentValue.current === ventureName) {
        onSearch([]);
      }
    }
  };

  const handleChange = useCallback(
    (e) => {
      const originalValue = e.target.value;
      clearTimeout(debouncedTimer.current);
      setIsLoading(false);
      setValue(originalValue);
      currentValue.current = originalValue.trim();

      debouncedTimer.current = setTimeout(() => {
        const trimmedValue = originalValue.trim();
        if (trimmedValue.length > 2) {
          setIsLoading(true);
          searchVentures(trimmedValue);
        } else {
          onSearch([]);
        }
      }, 800) as unknown as number;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSearch],
  );

  useEffect(() => {
    setValue(selectVenture?.ventureName || '');
  }, [selectVenture]);

  useEffect(() => {
    if (iteration) {
      setValue('');
      currentValue.current = '';
      onSearch([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [iteration]);

  return <>{inputRenderer({ value, onChange: handleChange, isLoading })}</>;
}

export default VentureAutocomplete;
