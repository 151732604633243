// region Submit
export interface Submit {
  type: 'Submit';
  payload: boolean;
}

export const submit = (payload: Submit['payload']): Submit => ({
  type: 'Submit',
  payload,
});
// endregion

// region CancellingError
export interface CancellingError {
  type: 'CancellingError';
  payload: string;
}

export const cancellingError = (
  payload: CancellingError['payload'],
): CancellingError => ({
  type: 'CancellingError',
  payload,
});
// endregion

// region CancellingSuccess
export interface CancellingSuccess {
  type: 'CancellingSuccess';
  payload: string;
}

export const cancellingSuccess = (
  payload: CancellingSuccess['payload'],
): CancellingSuccess => ({
  type: 'CancellingSuccess',
  payload,
});
// endregion

export type Actions = Submit | CancellingError | CancellingSuccess;
