import {
  CircularProgress,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import InfoIcon from '@material-ui/icons/Info';
import { AxiosError } from 'axios';
import { useSnackbar } from 'notistack';
import { ReactElement, useCallback, useContext, useRef, useState } from 'react';
import schedulingWizardApi, {
  SchedulingWizard,
} from '../../../api/schedulingWizard';
import { TenantTimeZone } from '../../../api/tenants/types/Settings';
import { Role } from '../../../api/user/Role';
import venturesAPI from '../../../api/ventures';
import { VentureId } from '../../../api/ventures/types/Venture';
import { useResourceBundles } from '../../../contexts/resource-bundles-context';
import { UserContext } from '../../../contexts/user-context';
import { COLORS } from '../../../theme/variables';
import { Email } from '../../../utils/String/Email';
import { TestId } from '../../Testing/TestId';
import SchedulingWizardInitialForm, {
  ParsedFormValues,
} from '../../forms/scheduling-wizard-initial-form';
import { Button, ConfirmDialog, Dialog, SnackMessage } from '../index';
import Text from '../text';

enum ScheduleWizardModalSteps {
  DECLINED = 'DECLINED',
  RESERVED = 'RESERVED',
  METHOD_SELECTION = 'METHOD_SELECTION',
  FORM = 'FORM',
}

const useStyles = makeStyles(() => ({
  buttonInfoIcon: {
    padding: 0,
    marginLeft: 7,
  },
  infoIcon: {
    color: COLORS.COLOR_GRAY_LIGHTENED_20,
    fontSize: 18,
  },
  buttonScheduling: {
    minWidth: 102,
  },
}));

export interface Props {
  ventureId: VentureId;
  ventureName: string;
}

export function CreateSchedulingWizardButton({
  ventureId,
  ventureName,
}: Props): ReactElement {
  const classes = useStyles();
  const { rb } = useResourceBundles();
  const { enqueueSnackbar } = useSnackbar();
  const { user, tokenData, hasRole } = useContext(UserContext);
  const formRef = useRef<HTMLFormElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [modalStep, setModalStep] = useState<ScheduleWizardModalSteps | null>(
    null,
  );
  const [wizards, setWizards] = useState<SchedulingWizard[]>([]);

  const resetState = useCallback(() => {
    setWizards([]);
  }, []);

  const onClose = useCallback(() => {
    setModalStep(null);
    resetState();
  }, [resetState]);

  const onError = useCallback(
    (errorMessage) => {
      enqueueSnackbar(
        'An error occurred while creating the scheduling wizard. Please, try again.',
        {
          content: (key, message) =>
            SnackMessage({
              key,
              message,
              variant: 'error',
              additionalMessage: errorMessage,
            }),
          variant: 'error',
        },
      );
      onClose();
    },
    [onClose, enqueueSnackbar],
  );

  const onOpen = useCallback(async () => {
    try {
      setIsLoading(true);
      const [founders, mentors, activeWizards] = await Promise.all([
        venturesAPI.getFounders(ventureId),
        venturesAPI.getMentors(ventureId),
        schedulingWizardApi.ventureActiveWizards(ventureId),
      ]);

      if (founders.length < 1 || mentors.length < 2) {
        setModalStep(ScheduleWizardModalSteps.DECLINED);
        resetState();
        return;
      }

      if (!!activeWizards.length) {
        setWizards(activeWizards);
        setModalStep(ScheduleWizardModalSteps.RESERVED);
        return;
      }

      setModalStep(ScheduleWizardModalSteps.METHOD_SELECTION);
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data?.message;
      onError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  }, [ventureId, resetState, onError]);

  const onCancelWizard = useCallback(async () => {
    try {
      setIsCanceling(true);
      await Promise.all(
        wizards.map(({ id }) => schedulingWizardApi.cancel(id)),
      );
      setModalStep(ScheduleWizardModalSteps.METHOD_SELECTION);
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data?.message;
      onError(errorMessage);
    } finally {
      setIsCanceling(false);
    }
  }, [wizards, onError]);

  const onAutoSchedule = useCallback(async () => {
    try {
      setIsCreating(true);
      await schedulingWizardApi.create(ventureId);
      enqueueSnackbar(
        'Scheduling process started successfully!\nNew session(s) will be scheduled in 4 days.',
        {
          variant: 'success',
        },
      );
      onClose();
    } catch (error) {
      const errorMessage = (error as AxiosError).response?.data?.message;
      onError(errorMessage);
    } finally {
      setIsCreating(false);
    }
  }, [ventureId, onClose, onError, enqueueSnackbar]);

  const onSchedule = useCallback(
    async (values: ParsedFormValues) => {
      try {
        setIsCreating(true);
        const payload = {
          ventureId,
          originatorEmail: values.email as Email,
          schedulingWizardDates: values.arrayDate.map((date) => ({
            startDateTime: date,
            endDateTime: date,
          })),
          schedulingWizardFounders: [],
          schedulingWizardMentors: [],
          physicalLocation:
            values.meeting === 'in-person' || values.meeting === 'hybrid'
              ? values.physicalLocation || null
              : null,
          virtualChannelNeeded:
            values.meeting === 'virtually' || values.meeting === 'hybrid',
        };

        if (hasRole(Role.Mentor)) {
          await schedulingWizardApi.createByMentor(payload);
        } else {
          await schedulingWizardApi.createByAdmin(payload);
        }

        enqueueSnackbar(
          'Scheduling process started successfully!\nNew session(s) will be scheduled in 4 days.',
          {
            variant: 'success',
          },
        );
        onClose();
      } catch (error) {
        const errorMessage = (error as AxiosError).response?.data?.message;
        onError(errorMessage);
      } finally {
        setIsCreating(false);
      }
    },
    [ventureId, hasRole, enqueueSnackbar, onClose, onError],
  );

  return (
    <>
      {(() => {
        switch (modalStep) {
          case ScheduleWizardModalSteps.RESERVED:
            return (
              <ConfirmDialog
                title={<Title />}
                body={
                  <TestId testId={'dialog-body-text'}>
                    <Text>
                      There is an active Scheduling Wizard currently at work. Do
                      you want to cancel the existing Scheduling Wizard for this
                      venture and start a new one?
                    </Text>
                  </TestId>
                }
                isOpen={true}
                onSuccess={onCancelWizard}
                onCancel={onClose}
                successProps={{
                  variant: 'contained',
                  'data-testid': 'approve-button',
                  disabled: isCanceling,
                  label: isCanceling ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Yes'
                  ),
                }}
                cancelProps={{
                  label: 'No',
                  'data-testid': 'cancel-button',
                  variant: 'outlined',
                }}
              />
            );
          case ScheduleWizardModalSteps.DECLINED:
            return (
              <Dialog
                title={<Title />}
                contentRenderer={() => (
                  <TestId testId='dialog-body-text'>
                    <Text variant={'normal'}>
                      Wizard cannot be started. Please make sure that at least 1
                      founder <br /> and 2 {rb('mentors')} are assigned to the
                      venture.
                    </Text>
                  </TestId>
                )}
                actions={
                  <Button
                    onClick={onClose}
                    data-testId={'close-button'}
                    variant='contained'>
                    I understand
                  </Button>
                }
                open={true}
                setOpen={onClose}
              />
            );
          case ScheduleWizardModalSteps.METHOD_SELECTION:
            return (
              <ConfirmDialog
                title={<Title />}
                body={
                  <span data-testid={'dialog-body-text'}>
                    Would you like to schedule a session for this venture
                    automatically or provide meeting times now?
                  </span>
                }
                isOpen={true}
                onSuccess={onAutoSchedule}
                onAdditional={() => setModalStep(ScheduleWizardModalSteps.FORM)}
                onCancel={onClose}
                successProps={{
                  variant: 'contained',
                  'data-testid': 'approve-button',
                  disabled: isCreating,
                  label: isCreating ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Automatically'
                  ),
                }}
                additionalProps={{
                  variant: 'contained',
                  'data-testid': 'form-button',
                  label: 'Provide times',
                }}
                cancelProps={{
                  variant: 'outlined',
                  label: 'Cancel',
                  'data-testid': 'cancel-button',
                }}
              />
            );
          case ScheduleWizardModalSteps.FORM:
            return (
              <ConfirmDialog
                title={
                  <Title tooltip='The scheduling wizard helps coordinate sessions, similar to Doodle. Select available dates to share with attendees for voting. Once two mentors and a founder agree on a time slot, the session will be scheduled in 4 days.' />
                }
                body={
                  <SchedulingWizardInitialForm
                    initialValues={{
                      email: tokenData?.email ?? '',
                      venture: ventureName,
                      meeting: 'virtually',
                      physicalLocation: undefined,
                    }}
                    onSubmit={onSchedule}
                    loading={isCreating}
                    timeZone={
                      user?.timeZone ?? ('US/Eastern' as TenantTimeZone)
                    }
                    noEmail
                    noSubmit
                    dateFullWidth
                    ref={formRef}
                  />
                }
                isOpen={true}
                onSuccess={() => formRef.current?.submit()}
                onCancel={onClose}
                successProps={{
                  variant: 'contained',
                  'data-testid': 'approve-button',
                  disabled: isCreating,
                  label: isCreating ? (
                    <CircularProgress size={24} color='inherit' />
                  ) : (
                    'Apply'
                  ),
                }}
                cancelProps={{
                  variant: 'outlined',
                  label: 'Cancel',
                  'data-testid': 'cancel-button',
                }}
              />
            );
          default:
            return null;
        }
      })()}
      <Button
        onClick={onOpen}
        variant='contained'
        data-testid='button-invite-all'
        className={classes.buttonScheduling}
        disabled={isLoading}
        startIcon={<AddIcon />}>
        Wizard
      </Button>
    </>
  );
}

function Title({ tooltip }: { tooltip?: string }): ReactElement {
  const classes = useStyles();

  return (
    <>
      Scheduling Wizard
      {tooltip && (
        <Tooltip title={tooltip}>
          <IconButton className={classes.buttonInfoIcon}>
            <InfoIcon className={classes.infoIcon} />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
