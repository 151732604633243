import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { ReactElement, useMemo } from 'react';
import { ColumnHeaderProps, ColumnType, ColumnTypeData } from './Column';
import classes from './Table.module.scss';
import { Badge } from './cells/Badge';
import { Check } from './cells/Check';
import { Link } from './cells/Link';
import { TimeRange } from './cells/TimeRange';

export interface TableProps<
  T extends { [k: string]: ColumnHeaderProps<ColumnType> },
> {
  columns: T;
  data: Array<{
    selected?: boolean;
    columns: { [k in keyof T]: ColumnTypeData<T[k]['type']> };
  }>;
}

export function Table<
  T extends { [k: string]: ColumnHeaderProps<ColumnType> },
>({ columns, data }: TableProps<T>) {
  const { columnsList, grid } = useMemo(
    () =>
      ({
        columnsList: Object.entries(columns),
        grid: Object.values(columns)
          // eslint-disable-next-line array-callback-return
          .map((column): string => {
            switch (column.type) {
              case 'badge':
                return 'auto';
              case 'link':
                return 'minmax(auto, 1fr)';
              case 'name':
                return 'minmax(180px, 2fr)';
              case 'timeRange':
              case 'check':
              case 'preIcon':
                return 'auto';
            }
          })
          .join(' '),
      } as const),
    [columns],
  );

  return (
    <div className={classes.root} style={{ gridTemplateColumns: grid }}>
      <div className={classes.row}>
        {/* eslint-disable-next-line array-callback-return */}
        {columnsList.map(([k, v]): ReactElement => {
          switch (v.type) {
            case 'check':
              return (
                <div
                  className={`${classes.headerCell} ${classes.check}`}
                  key={k}>
                  <Check checked={v.checked} onChange={v.onChange} testId={k} />
                </div>
              );
            case 'link':
            case 'timeRange':
            case 'badge':
            case 'name':
              return (
                <Typography
                  variant={'subtitle2'}
                  key={k}
                  className={classes.headerCell}>
                  {v.title}
                </Typography>
              );
            case 'preIcon':
              return (
                <Typography
                  variant={'subtitle2'}
                  key={k}
                  className={classNames(classes.headerCell, classes.preIcon)}>
                  {v.title}
                </Typography>
              );
          }
        })}
      </div>
      {data.map((v, i) => (
        <div
          className={classNames(classes.row, {
            [classes.selected]: v.selected,
          })}
          key={i}>
          {/* eslint-disable-next-line array-callback-return */}
          {columnsList.map(([k, { type }]): ReactElement => {
            switch (type) {
              case 'name': {
                const props = v.columns[k] as ColumnTypeData<'name'>;
                return (
                  <div className={classes.cell}>
                    <Link label={props.label} link={props.link} testId={k} />
                  </div>
                );
              }
              case 'timeRange': {
                const props = v.columns[k] as ColumnTypeData<'timeRange'>;
                return (
                  <div className={classes.cell}>
                    <TimeRange range={props} testId={k} />
                  </div>
                );
              }
              case 'badge': {
                const props = v.columns[k] as ColumnTypeData<'badge'>;
                return (
                  <div className={classes.cell}>
                    <Badge
                      label={props.label}
                      variant={props.variant}
                      testId={k}
                    />
                  </div>
                );
              }
              case 'link': {
                const props = v.columns[k] as ColumnTypeData<'link'>;
                return (
                  <div className={classes.cell}>
                    <Link label={props.label} link={props.link} testId={k} />
                  </div>
                );
              }
              case 'check': {
                const props = v.columns[k] as ColumnTypeData<'check'>;
                return (
                  <div className={classNames(classes.cell, classes.check)}>
                    <Check
                      testId={k}
                      checked={props.checked}
                      onChange={props.onChange}
                    />
                  </div>
                );
              }
              case 'preIcon': {
                const element = v.columns[k] as ColumnTypeData<'preIcon'>;
                return (
                  <div className={classNames(classes.cell, classes.preIcon)}>
                    {element}
                  </div>
                );
              }
            }
          })}
        </div>
      ))}
    </div>
  );
}
